<template>
  <div>
    <div
      class="container mx-auto border-secondary-200 border rounded-lg flex justify-center p-4 flex-col items-center relative mt-4 shadow-xl h-[25rem]"
      v-if="boxWrapper"
    >
      <Transition name="list">
        <div class="relative lootBox" v-if="!openBoxRef" key="1">
          <LootBox
            v-if="boxWrapper"
            :box="boxWrapper?.box"
            class="boxWrapper"
          ></LootBox>
          <div
            class="absolute inset-0 flex justify-center gap-4 items-center flex-col"
            v-if="!hitLimit"
          >
            <button
              class="px-4 py-2 rounded-lg border border-primary-200 openButton transition-all duration-200 ease-in-out"
              @click="openBoxButton()"
              v-if="userStore.authState !== AuthState.Anon"
            >
              <span v-if="!loading">open</span>
              <PhSpinner v-else class="animate-spin"></PhSpinner>
            </button>
            <button
              class="px-4 py-2 rounded-lg border border-primary-200 openButton transition-all duration-200 ease-in-out"
              @click="openDemoBox()"
            >
              <span v-if="!loading">Demo Open</span>
              <PhSpinner v-else class="animate-spin"></PhSpinner>
            </button>
          </div>
          <div
            class="absolute inset-0 flex justify-center gap-4 items-center flex-col"
            v-else
          >
            <div
              class="border rounded-lg w-12 h-12 border-red-500 p-4 text-red-500 flex items-center flex-col gap-2 hover:w-32 hover:h-20 group transition-all duration-200 ease-in-out select-none"
            >
              <PhLock class="shrink-0"></PhLock>
              <div
                class="text-center hidden w-0 group-hover:block group-hover:w-fit overflow-hidden"
              >
                Out Of Stock
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="list">
        <Roullet
          :box-wrapper="boxWrapper!"
          v-if="openBoxRef"
          key="2"
          :winner="winnerID"
          @animation-done="handleDone()"
        ></Roullet>
      </Transition>
      <div class="absolute inset-0 flex justify-between i pointer-events-none">
        <div class="flex flex-col p-4">
          <div>
            {{
              massOpenSelections > 1 && openingAmount > 0
                ? `${openingAmount}`
                : ""
            }}
          </div>
        </div>
        <div class="flex flex-col justify-between items-end">
          <RouterLink
            to="/how-it-works"
            class="rounded-full border border-secondary-200 w-8 aspect-square text-center flex items-center justify-center m-4 hover:border-secondary-400 pointer-events-auto select-none cursor-pointer"
          >
            ?
          </RouterLink>
          <ControllSection
            v-if="!openBoxRef"
            :box-wrapper="boxWrapper!"
            :showShipping="firstBox"
            :is-international="isInternational"
            @blind-open="handleBlindOpen()"
            :hide-blind-open="isFreeBox"
          >
          </ControllSection>
        </div>
      </div>
    </div>
    <div v-if="latesOpenBoxDate && isFreeBox" class="flex justify-center">
      <Countdown :end-date="latesOpenBoxDate!"></Countdown>
    </div>

    <div class="flex justify-center my-2" v-if="!isFreeBox">
      <SelectButton
        v-model="massOpenSelections"
        :options="massOpenOptions"
      ></SelectButton>
    </div>
    <div class="container mx-auto my-2 relative h-[170px]">
      <TopPulls
        v-if="boxWrapper"
        :box-i-d="boxWrapper!.box.id"
        :box-wrapper="boxWrapper"
      ></TopPulls>
    </div>

    <div class="container mx-auto flex flex-col gap-4 pt-4 mb-4 px-4 lg:px-0">
      <h2 class="text-2xl">Content</h2>

      <div
        class="flex flex-row gap-4 flex-wrap md:justify-start justify-center"
        v-if="boxWrapper"
        v-auto-animate
      >
        <div
          v-for="item in sortedWinnables"
          class="relative hover:shadow-xl transition-all duration-100 rounded-lg cursor-pointer"
          :key="item.id"
          @click="handleOP($event, item)"
        >
          <WinnableLot
            :winnable="item"
            :rarity="boxWrapper.getCalculatedRarity(item.id)"
          >
          </WinnableLot>
          <div
            class="absolute inset-2 flex flex-col justify-between items-end text-sm text-shadow pointer-events-none"
          >
            <div>{{ calculateLimit(item.id) }}</div>
            {{ (boxWrapper.getWinnableWeight(item.id) / 1000).toFixed(4) }} %
          </div>
        </div>
      </div>
    </div>
    <OverlayPanel ref="op" pt:root:class="bg-secondary-300 ">
      <div class="flex gap-2 items-center">
        <h1>{{ activeWinnable!.name }}</h1>
        <a
          :href="`https://republicbricks.com/products/${activeWinnable?.handle_name}`"
          class="hover:text-primary-300 transition-all duration-200"
          ><PhExport size=" 24"></PhExport
        ></a>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref } from "vue";
import trooper from "../assets/trooper.png";
import Roullet from "../components/Chest/Roullet.vue";
import {
  getBoxWrapper,
  isFirstBox,
  openBox,
} from "../services/SupabaseService";
import { BoxWrapper } from "../classes/BoxWrapper";
import { useRoute, useRouter } from "vue-router";
import WinnableLot from "../components/admin/NewBox/WinnableLot.vue";
import LootBox from "../components/Boxes/LootBox.vue";
import {
  openAddressInfoModal,
  openBlindOpenModal,
  openBuyModal,
  openWinModal,
} from "../stores/ModalStore";
import { PhSpinner, PhExport, PhLock } from "@phosphor-icons/vue";
import { useLiveCoins } from "../composable/useLiveCoins";
import { useCurrency } from "../composable/useCurrency";
import { getCustomer } from "../services/userService";
import ControllSection from "../components/Chest/ControllSection.vue";
import { AuthState, useUserStore } from "../stores/UserStore";
import { BoxLimit, Winnable } from "../model/supabase";
import TopPulls from "../components/Chest/TopPulls.vue";
import { useLimitsByBoxID } from "../composable/useLimits";
import { useWatchSpecificItems } from "../composable/useWatchOpenBoxes";
import { addHours, differenceInHours } from "date-fns";
import Countdown from "../components/general/Countdown.vue";
import { watch } from "vue";
import { openBoxLogic as demoLogic } from "../services/BoxService";

const boxWrapper: Ref<BoxWrapper | undefined> = ref();

const activeWinnable = ref<Winnable | undefined>(undefined);

const op = ref(null);

const userStore = useUserStore();

const sortByCat = ["Rarity", "Price", "Percentage"] as const;
const activeCategory = ref<(typeof sortByCat)[number]>("Rarity");

const limits = ref<BoxLimit | undefined>();

const hasAddressRef = ref(false);
const isInternational = ref(false);

const massOpenSelections = ref(1);
const massOpenOptions = [1, 2, 3, 4, 5, 10];
const openingAmount = ref(0);
const loading = ref(false);
const router = useRouter();
const route = useRoute();
const boxID = ref(route.params.id as string);
const winnerID = ref("");
const isDemo = ref(false);

const relatedOpenBoxes = useWatchSpecificItems(boxID.value);

const firstBox = ref(false);

const openBoxRef = ref(false);

const isFreeBox = computed(() => {
  if (!boxWrapper.value || !boxWrapper.value.box.free_type) return false;
  return true;
});

const latestOpenBox = computed(() => {
  if (!userStore.user?.id) return;
  const openBoxesByUser = relatedOpenBoxes.value.filter(
    (box) => box.openBox.buyer_id === userStore.user!.id
  );
  return openBoxesByUser[0];
});

const latesOpenBoxDate = computed(() => {
  if (latestOpenBox.value) {
    const lastBoxOpenend = new Date(latestOpenBox.value.openBox.created_at);
    const addedDate = new Date(addHours(lastBoxOpenend, 24));
    return addedDate;
  }
});

const hitLimit = computed(() => {
  if (boxWrapper.value?.box.free_type && userStore.user?.id) {
    const openBoxesByUser = relatedOpenBoxes.value.filter(
      (box) => box.openBox.buyer_id === userStore.user!.id
    );
    if (openBoxesByUser.length !== 0) {
      const lastOpened = new Date(openBoxesByUser[0].openBox.created_at);
      const difference = differenceInHours(new Date(), lastOpened);

      switch (boxWrapper.value.box.free_type) {
        case "daily":
          return difference < 24;
        case "weekly":
          return difference < 168;
        case "monthly":
          return difference < 720;
      }
    }
  }

  if (!limits.value) return false;

  const hasOpenLimit = limits.value.opening_amount !== 0;
  if (
    hasOpenLimit &&
    relatedOpenBoxes.value.length >= limits.value.opening_amount
  ) {
    return true;
  }

  const hasUserOpenLimit = limits.value.specific_user_opening_amount !== 0;
  if (
    hasUserOpenLimit &&
    relatedOpenBoxes.value.length >= limits.value.specific_user_opening_amount
  ) {
    return true;
  }

  const hasItemsLimit = limits.value.item_pulled.length !== 0;
  if (!hasItemsLimit) return false;

  return limits.value.item_pulled.some(
    (item) =>
      relatedOpenBoxes.value.filter((i) => i.openBox.winnable_id === item.name)
        .length >= item.amount
  );
});

const SHIPPING_PRICE = computed(() => {
  if (!firstBox.value) return 0;
  if (isInternational.value) return 20000;
  return 7000;
});

function openDemoBox() {
  const copiedList = [...boxWrapper.value!.boxWinnables!].map((winnable) =>
    JSON.parse(JSON.stringify(winnable))
  );
  const winner = demoLogic(copiedList);
  console.log(winner);
  winnerID.value = winner.Winnables.id;
  openBoxRef.value = true;
  isDemo.value = true;
}

async function handleOP(event: Event, winnable: Winnable) {
  if (activeWinnable.value?.id === winnable.id) return;

  activeWinnable.value = winnable;

  await op.value!.hide(event);
  op.value!.show(event);
}

function calculateLimit(id: string) {
  if (!limits.value) return "";
  const itemLimit = limits.value.item_pulled.find((item) => item.name === id);
  if (!itemLimit) return "";

  const itemPulled = relatedOpenBoxes.value.filter(
    (i) => i.openBox.winnable_id === id
  );
  return `${itemPulled.length}/${itemLimit.amount}`;
}

async function openBoxButton() {
  isDemo.value = false;
  if (userStore.authState === AuthState.Anon) {
    router.push("/login");
    return;
  }

  if (!hasAddressRef.value) {
    await openAddressInfoModal();
    return;
  }

  const coins = useLiveCoins();

  const shipping = SHIPPING_PRICE.value;

  if (coins.value < boxWrapper.value!.box.price + shipping) {
    openBuyModal();
    return;
  }

  if (loading.value) return;
  loading.value = true;
  try {
    const winner = await openBox(boxWrapper.value!.box.id);
    winnerID.value = winner;
    openBoxRef.value = true;
    firstBox.value = false;
    openingAmount.value += 1;
  } catch (e) {
  } finally {
    loading.value = false;
  }
}

async function handleDone() {
  const winner = boxWrapper.value!.getWinnableByID(winnerID.value!);
  const loggedIn = userStore.authState !== AuthState.Anon;
  await openWinModal(
    winner!,
    isDemo.value,
    loggedIn,
    boxWrapper.value!.getCalculatedRarity(winnerID.value!)
  );
  openBoxRef.value = false;

  if (isDemo.value) return;

  if (openingAmount.value < massOpenSelections.value) {
    openBoxButton();
  } else if (openingAmount.value === massOpenSelections.value) {
    openingAmount.value = 0;
  }
}

const sortedWinnables = computed(() => {
  return boxWrapper.value!.winnables.sort(
    (a, b) => b.estimated_price - a.estimated_price
  );
});

async function handleBlindOpen() {
  const modalReq = await openBlindOpenModal(
    boxWrapper.value!,
    !firstBox.value!
  );

  const { shipped, bought } = modalReq;

  if (shipped) {
    firstBox.value = true;
  }
  if (firstBox.value && bought) {
    firstBox.value = false;
  }
}

async function init() {
  boxWrapper.value = await getBoxWrapper(boxID.value);
  limits.value = await useLimitsByBoxID(boxID.value);
  firstBox.value = await isFirstBox();
}
onMounted(async () => {
  await init();
  const customer = await getCustomer();

  hasAddressRef.value = !!customer.address_id;
  isInternational.value = !!customer.is_international;
});
</script>

<style scoped>
.lootBox {
  transition: all 0.3s ease-in-out;
}

.lootBox:hover .boxWrapper {
  @apply blur-[2px];
}
.lootBox:hover .openButton {
  @apply bg-primary-200 shadow-lg;
}

.list-enter-active,
.list-leave-active {
  position: absolute;
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.text-shadow {
  text-shadow: #000 1px 0 10px;
}
</style>
