<template>
  <div
    class="modal w-1/4 h-2/4 border border-secondary-300 rounded-lg bg-secondary-300 flex justify-between items-center flex-col p-4"
  >
    <h2>No Address Detected</h2>

    <div class="text-center">
      Before opening the box, please make sure you have an address set in your
      settings.
    </div>
    <div class="flex justify-center">
      <button @click="closeModal" class="px-4 py-2 rounded-lg bg-primary-300">
        Close
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from "../../../stores/ModalStore";

const modalStore = useModalStore();

function closeModal() {
  modalStore.visible = false;
}
</script>

<style scoped lang="scss"></style>
