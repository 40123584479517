<template>
  <label class="flex flex-col">
    {{ label }}
    <input
      :value="modelValue"
      :checked="!!modelValue"
      @input="update($event)"
      class="bg-secondary-300 px-2 py-1.5 rounded-lg Autocomplete"
      :type="type"
      :min="min"
      :max="max"
    />
  </label>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { RBAbstractInput } from "../../model/components/AbstractInput";

interface Props extends RBAbstractInput<string | number | boolean> {
  type?: string;
  min?: number;
  max?: number;
}

defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

async function update(event: Event) {
  emit("update:modelValue", (event.target as HTMLInputElement).value);
}
</script>

<style scoped lang="scss"></style>
