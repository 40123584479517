<template>
  <RouterLink
    :to="`/chest/${props.wrapper.openBox.box_id}`"
    class="rounded-xl  flex flex-col lootBox items-center border hover:shadow-2xl hover:border-primary-600 hover:scale-[0.97]  bgImage transition-transform duration-200 relative w-[160px] h-[190px] border-secondary-300 overflow-hidden group "
    v-if="wrapper.box"
  >
    <img
      :src="useUrlSizing(props.mainWinnable?.image_src!)"
      class="w-full h-[60%] object-cover "
      loading="lazy"
    ></img>
    <div
      class=" trooper w-full flex  justify-center items-center flex-col select-none  h-full  overflow-hidden "
    >
      <div class="text-center text-lg text-shadow select-none ">
        {{ wrapper.box.name }} Box
      </div>
   
      <div class="flex items-center group-hover:h-4 h-0 overflow-hidden transition-all duration-200">
       <StudIcon></StudIcon> {{ wrapper.box.price }}
      </div>
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from "vue";
import boxImg from "../assets/chest.png";
import trooper from "../assets/trooper.png";
import { Box, Winnable } from "../model/supabase";
import { supabase } from "../services/SupabaseService";
import { PhCoins } from "@phosphor-icons/vue";
import snapSound from "../assets/sounds/boxSnap.mp3";
import { NewOpenBoxWrapper } from "../classes/NewOpenBoxWrapper";
import { useRarityStyle } from "../composable/useRarityStyle";
import { useUrlSizing } from "../composable/useUrlSizing";
import StudIcon from "./general/StudIcon.vue";

type Props = {
  wrapper: NewOpenBoxWrapper;

  mainWinnable?: Winnable;

};


const props = defineProps<Props>();



</script>

<style scoped>
.bgImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.text-shadow {
  text-shadow: #000 1px 0 5px;
}

</style>
