import { Product } from "shopify-admin-api-typings";
import { getShopifyItems } from "../services/ShopifyService";

type PullItemReturn = {
  products: Product[];
  next: string;
  previos: string;
};

async function* shopifyGenerator(collection: string) {
  let pageInfo: string | undefined = undefined;
  do {
    const page: PullItemReturn = await getShopifyItems(pageInfo, collection);
    pageInfo = page.next;
    yield page.products;
  } while (pageInfo);
}

export function useShopifyPagination(itemsPerPage: number, collection: string) {
  const itemArray: Product[] = [];

  const shopifyItems = shopifyGenerator(collection);

  const getPages = async (page: number) => {
    const neededItems = page * itemsPerPage;
    let isDone = false;

    const start = neededItems - itemsPerPage;
    const end = neededItems;

    if (neededItems < itemArray.length) {
      return itemArray.slice(start, end);
    }

    do {
      const items = await shopifyItems.next();
      isDone = !!items.done;
      itemArray.push(...(items.value || []));
    } while (itemArray.length < neededItems && !isDone);

    return itemArray.slice(start, end);
  };
  return getPages;
}
