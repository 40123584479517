<template>
  <div class="relative h-full">
    <Transition name="winnable">
      <KeepAlive>
        <ShowShopify
          v-if="!selectedItem"
          @select-product="selectedItem = $event"
        ></ShowShopify>
        <NewWinnable
          v-else
          :selected-item="selectedItem"
          @unselect-item="selectedItem = null"
        >
        </NewWinnable>
      </KeepAlive>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ShowShopify from "./ShowShopify.vue";
import NewWinnable from "./NewWinnable.vue";
import { ref } from "vue";
import RBInput from "../../inputs/RBInput.vue";

const selectedItem = ref(null);
</script>

<style scoped>
.winnable-enter-active,
.winnable-leave-active {
  position: absolute;
  transition: all 0.5s ease;
}
.winnable-enter-from,
.winnable-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
