<template>
  <div
    class="border rounded-lg border-secondary-500 p-4 bg-secondary-200 flex flex-col gap-4"
  >
    <div class="flex justify-between">
      <h1 class="text-xl">All Boxes</h1>

      <div>
        <RouterLink
          to="/admin/new-box"
          class="rounded-lg bg-primary-200 py-2 px-4"
        >
          Create new Box</RouterLink
        >
      </div>
    </div>
    <div>
      <SelectButton
        :options="selectOptions"
        v-model="selectedOption"
      ></SelectButton>
    </div>

    <div class="flex flex-col gap-4">
      <Display
        v-for="(box, i) in displayedBoxes"
        :box="box"
        :boxes-opening="boxesOpening[box.id]"
        :box-proift="boxProift[box.id]"
        :open="openTile === box.id"
        @click="openTile = box.id"
        @archive="handleArchive(i)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { Box } from "../../model/supabase";
import {
  getBoxes,
  getBoxOpenings,
  getProfitOfAllBoxes,
} from "../../services/SupabaseService";
import Display from "./BoxDisplay/Display.vue";
import { archiveBox } from "../../services/AddService";

const boxes = ref<Box[]>([]);

const boxesOpening = ref<Record<string, number>>({});
const boxProift = ref<Record<string, number>>({});
const openTile = ref("");

const selectOptions = ["All", "Active", "Archived"];

const selectedOption = ref("Active");

async function handleArchive(index: number) {
  const box = boxes.value[index];
  const boxID = box.id;
  boxes.value[index] = await archiveBox(boxID, box.archived);
}

const displayedBoxes = computed(() => {
  if (selectedOption.value === "All") {
    return boxes.value;
  }
  if (selectedOption.value === "Active") {
    return boxes.value.filter((box) => !box.archived);
  }
  if (selectedOption.value === "Archived") {
    return boxes.value.filter((box) => box.archived);
  }
});

onMounted(async () => {
  const unsortedBoxes = await getBoxes();
  boxes.value = unsortedBoxes.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  boxesOpening.value = (await getBoxOpenings()) || {};
  boxProift.value = (await getProfitOfAllBoxes()) || {};
});
</script>

<style scoped lang="scss"></style>
