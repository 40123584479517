<template>
  <div class="h-[100vh] w-[100vw] bg-secondary overflow-scroll flex flex-col">
    <Modal></Modal>

    <Navbar class="shrink-0" />
    <div class="grid grid-flow-row lg:grid-flow-col gap-4 widthSettings">
      <Navigation class="hidden lg:block"></Navigation>
      <div class="grid grid-flow-row lg:grid-flow-col innerWidth gap-4">
        <div class="overflow-auto relative">
          <router-view v-slot="{ Component }">
            <transition name="route">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <NewestDrop class="directionCall"></NewestDrop>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Navbar from "./components/Navbar.vue";
import { getCoinsForUserID, supabase } from "./services/SupabaseService";
import { useUserStore } from "./stores/UserStore";
import Modal from "./components/Modal/Modal.vue";
import NewestDrop from "./components/NewestDrop.vue";
import { useUserTracking } from "./composable/useUserTracking";
import Navigation from "./components/Home/Navigation.vue";

const userStore = useUserStore();
supabase.auth.onAuthStateChange(async (event, session) => {
  if (event === "INITIAL_SESSION") {
    if (session?.user.id) {
      const coins = await getCoinsForUserID(session.user.id);
      userStore.signIn(session.user, coins.coins);
    }
  }
  if (event === "SIGNED_OUT") {
    userStore.signOut();
  }
  if (event === "SIGNED_IN") {
    if (session?.user.id && !userStore.user) {
      // const coins = await getCoinsForUserID(session.user.id);
      // userStore.signIn(session.user, coins.coins || 0);
    }
  }
});

useUserTracking();
</script>

<style scoped>
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-from,
.route-leave-to {
  position: absolute;
  transform: scale(0.99);
  width: 100%;
  opacity: 0;
}

.directionCall {
  grid-row-start: 1;
}
.innerWidth {
  grid-template-rows: 18rem 1fr;
}
.widthSettings {
  grid-template-columns: 1fr;
}

@media screen(lg) {
  .widthSettings {
    grid-template-columns: 13rem 1fr;
  }
  .innerWidth {
    grid-template-columns: 1fr 13rem;
    grid-template-rows: 1fr;
  }
}
</style>
