function getRandomInt() {
  const buffer = new ArrayBuffer(8);

  // View it as an Int8Array and fill it with 8 random ints
  const ints = new Int8Array(buffer);
  window.crypto.getRandomValues(ints);

  // Set the sign (ints[7][7]) to 0 and the
  // exponent (ints[7][6]-[6][5]) to just the right size
  // (all ones except for the highest bit)
  ints[7] = 63;
  ints[6] |= 0xf0;

  // Now view it as a Float64Array, and read the one float from it
  const float = new DataView(buffer).getFloat64(0, true) - 1;
  return float;
}

export function openBoxLogic(winnables: any[]) {
  let choosenWinnable;

  const rndNumber = getRandomInt() * 100000;

  console.log("rnd", getRandomInt());
  console.log("rounded", rndNumber);

  let collectedWeight = 0;

  for (let i = 0; i < winnables.length; i++) {
    const realWeight = winnables[i].weight + collectedWeight;
    collectedWeight += winnables[i].weight;
    winnables[i].weight = realWeight;
  }
  console.log(collectedWeight);
  const sortedWinnables = winnables.sort((a, b) => a.weight - b.weight);

  for (let i = 0; i < sortedWinnables.length; i++) {
    if (choosenWinnable) break; // Exit the loop if a winnable item has been chosen
    const item = winnables[i];
    if (rndNumber <= item.weight) {
      choosenWinnable = item;
    }
  }

  console.log(winnables.map((e) => e.weight));

  return choosenWinnable;
}
