<template>
  <div
    class="p-4 flex flex-col lg:items-center gap-4 overflow-hidden bg-secondary lg:border-l-[1px] border-secondary-200 h-full"
  >
    <h1 class="text-xl">Recently Won</h1>
    <div
      class="flex lg:flex-col flex-row gap-2 overflow-y-auto overflow-x-visible h-[85vh]"
      v-auto-animate
    >
      <div
        v-if="loaded"
        v-for="wrapper in shownArray"
        class="flex-shrink-0"
        :key="`${wrapper.openBox.id}`"
      >
        <NewestDropChest
          v-if="wrapper.winnable"
          :main-winnable="wrapper.winnable"
          :wrapper="wrapper"
        />
      </div>
      <div v-else v-for="x in 7">
        <div
          class="skeleton overflow-hidden rounded-xl p-2 flex flex-col lootBox items-center border hover:shadow-2xl hover:border-primary-600 hover:scale-105 bgImage transition-transform duration-200 relative w-[160px] h-[190px] border-secondary-300"
        >
          <span class="skeleton-block"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, Ref, ref } from "vue";
import { supabase, getNewestOpenBoxes } from "../services/SupabaseService";
import { RealtimeChannel, REALTIME_LISTEN_TYPES } from "@supabase/supabase-js";
import { OpenBoxes } from "../model/supabase";
import { NewOpenBoxWrapper } from "../classes/NewOpenBoxWrapper";
import NewestDropChest from "./NewestDropChest.vue";
import { useWatchOpenBoxes } from "../composable/useWatchOpenBoxes";

const subscriberRef: Ref<RealtimeChannel | null> = ref(null);
const insertArray: Ref<NewOpenBoxWrapper[]> = ref([]);

const loaded = computed(() => insertArray.value.length > 0);

const shownArray = computed(() => {
  const reversedArray = [...insertArray.value].reverse().slice(0, 30);
  return reversedArray;
});

useWatchOpenBoxes((wrapper) => {
  insertArray.value.push(wrapper);
});

onMounted(async () => {
  const newestBoxes = await getNewestOpenBoxes();
  const wrappers = newestBoxes.map((box) => new NewOpenBoxWrapper(box));

  wrappers.sort(
    (a, b) =>
      new Date(a.openBox.created_at).getTime() -
      new Date(b.openBox.created_at).getTime()
  );
  insertArray.value = wrappers;
});

onUnmounted(() => {
  if (subscriberRef.value) {
    subscriberRef.value.unsubscribe();
  }
});
</script>

<style scoped lang="scss">
.skeleton :deep(span) > *,
.skeleton :deep(.skeleton-block) > * {
  @apply invisible;
}

.skeleton :deep(span:after),
.skeleton :deep(.skeleton-block:after) {
  animation-duration: 1.5s;
  animation-name: wave;
  animation-iteration-count: infinite;
  content: "";
  transform: translateX(-100%);
  @apply top-0 bottom-0 right-0 left-0 absolute bg-gradient-to-r from-transparent via-[#ffffff0a] to-transparent;
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
