<template>
  <div class="modal">
    <div
      class="w-1/3 border-r-2 border-secondary-400 flex flex-col gap-2 justify-between"
    >
      <div class="flex flex-col gap-1">
        <div
          v-for="(tag, short) in navigation"
          @click="changeContent(short)"
          class="px-4 py-2 rounded-l-lg cursor-pointer"
          :class="{ active: short === activeNavigation }"
        >
          {{ tag }}
        </div>
      </div>
      <button class="m-4 py-2 rounded-lg bg-primary-300" @click="handleClose">
        Close
      </button>
    </div>
    <div class="modalContent">
      <Suspense>
        <component :is="activeComponent"></component>
        <template #fallback> loading... </template>
      </Suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import NewWinnable from "./NewWinnable.vue";
import SavedWinnable from "./SavedWinnable.vue";
import ShowShopify from "./ShowShopify.vue";
import newWinnableWrapper from "./newWinnableWrapper.vue";
import CollectionImport from "./CollectionImport.vue";
import { useModalStore } from "../../../stores/ModalStore";

const isInvalid = ref(false);
const navigation = {
  collectionImport: "Collection Import",
  newWinnable: "New Winnable",
  savedWinnables: "Saved Winnables",
};

const activeComponent = computed(() => {
  switch (activeNavigation.value) {
    case "newWinnable":
      return newWinnableWrapper;
    case "savedWinnables":
      return SavedWinnable;
    case "collectionImport":
      return CollectionImport;
    default:
      return NewWinnable;
  }
});

const activeNavigation = ref("collectionImport");

function changeContent(content: string) {
  activeNavigation.value = content;
}

function handleClose() {
  useModalStore().returnWinnable = [];
  useModalStore().visible = false;
}
</script>

<style scoped>
.modal {
  width: 70%;
  height: 60%;
  background-color: var(--tile);
  @apply rounded-lg flex bg-secondary-300 border border-secondary-500;
}
.modalContent {
  @apply w-full;
}
.active {
  @apply bg-secondary-400 text-primary-600;
}
</style>
