<template>
  <div class="h-[7rem] p-4 flex justify-between shadow-xl items-center">
    <Transition name="nav">
      <Navigation
        class="absolute inset-0 z-50 lg:hidden"
        v-if="menu"
        @close="menu = false"
      ></Navigation>
    </Transition>
    <RouterLink
      to="/"
      class="w-[250px] hidden lg:flex items-center justify-center h-full"
    >
      <img :src="logo" alt="" />
    </RouterLink>

    <div
      class="flex justify-between lg:justify-end items-center gap-4 w-full flex-row-reverse lg:flex-row px-4"
    >
      <LoginWidget></LoginWidget>
      <PhList size="30" class="lg:hidden" @click="openMenu" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import logo from "../assets/rb-logo.avif";
import LoginWidget from "./navbar/LoginWidget.vue";
import { PhList } from "@phosphor-icons/vue";
import Navigation from "./Home/Navigation.vue";

const menu = ref(false);

function openMenu() {
  menu.value = !menu.value;
}
</script>

<style scoped lang="scss">
.nav-enter-active,
.nav-leave-active {
  position: absolute;
  transition: all 0.3s ease;
}
.nav-enter-from,
.nav-leave-to {
  opacity: 0;
}
</style>
