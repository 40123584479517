<template>
  <div class="flex flex-col gap-4 container mx-auto my-4">
    <h1 class="text-2xl">How It Works</h1>
    <Stepper orientation="vertical" v-model:active-step="activeStep">
      <StepperPanel header="Step 1: Create an account">
        <div class="flex gap-4 flex-col mb-4">
          <p>
            If you already have an account on
            <a
              href="http://republicbricks.com"
              class="underline text-primary-300"
              >republicbricks.com</a
            >, registering with the same email will automatically pull your
            address information. It's a seamless process to ensure your
            convenience and accuracy when placing orders. No need to re-enter
            your details, we've got you covered!
          </p>
          <div>
            <RouterLink
              to="/signup"
              class="px-4 py-2 rounded-lg bg-primary-300 mt-4"
              >Sign up</RouterLink
            >
          </div>
        </div>
      </StepperPanel>
      <StepperPanel header="Step 2: Get some coins">
        <div class="flex flex-col gap-4 mb-4">
          <p>
            These coins come in a variety of package sizes, and the more you
            buy, the cheaper they get. Take advantage of bulk pricing to get the
            best value for your money!
          </p>
          <div>
            <RouterLink
              to="/buy-coins"
              class="px-4 py-2 rounded-lg bg-primary-300 mt-4"
              >Buy Coins</RouterLink
            >
          </div>
        </div>
      </StepperPanel>
      <StepperPanel header="Step 3: Find a box you like">
        <p>
          Our boxes are available in different price ranges, each offering a
          variety of winning chances. We want to make sure there's something for
          everyone, so you can choose the box that best suits your preferences
          and budget. Whether you're looking for a higher chance of winning or
          prefer a more affordable option, we've got you covered. Happy
          exploring!
        </p>
      </StepperPanel>
      <StepperPanel
        header="Step 4: Open the box and pay shipping on your first box"
      >
        <p>
          Your first box will include a shipping fee that covers the whole
          order. Subsequent boxes will also be included in these shipping fees.
        </p>
      </StepperPanel>
      <StepperPanel header="Step 5: Fill your order">
        <p>
          After you've opened your first box, you have a 24-hour window to open
          another and reset the timer. If you don't open another package within
          24 hours, or if it's been a week since your first opening, your items
          will be shipped to you.
        </p>
      </StepperPanel>
      <StepperPanel header="Step 6: Get your Prizes">
        <p>
          Your items will be packaged at our warehouse in Florida. We take care
          to ensure your orders are carefully handled and prepared for shipping
          from our Florida facility. Your items are in good hands!
        </p>
      </StepperPanel>
    </Stepper>
  </div>
</template>

<script setup lang="ts">
import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";

import { ref } from "vue";
import { AuthState, useUserStore } from "../stores/UserStore";
import { useLiveCoins } from "../composable/useLiveCoins";

const userStore = useUserStore();
const coins = useLiveCoins();

const activeStep = ref(findFirstStep());

function findFirstStep() {
  if (userStore.authState === AuthState.Anon) {
    return 0;
  }
  if (coins.value === 0) {
    return 1;
  }
  return 2;
}
</script>

<style scoped lang="scss"></style>
