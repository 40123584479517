<template>
  <div
    class="modal w-full m-4 md:w-2/4 h-2/4 border border-secondary-300 rounded-lg bg-secondary-300 flex justify-between items-center flex-col p-4"
  >
    <div class="flex flex-col items-center h-full gap-2">
      <h1 class="text-2xl">Shipment Details</h1>
      <h2 class="text-start">Items</h2>
      <div
        class="flex flex-wrap overflow-auto gap-4 items-center justify-center"
      >
        <WinnableLot
          :winnable="inv.open_boxes.Winnables!"
          v-for="inv in inventory"
        />
      </div>
      <div>
        <button
          class="px-4 py-2 bg-primary-300 rounded-lg"
          @click="handleClose"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useModalStore } from "../../../stores/ModalStore";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";
import { Inventory } from "../../../model/supabase";
import { getInventoryByShipmentID } from "../../../services/SupabaseService";

const modalStore = useModalStore();

const inventory = ref<Inventory[]>([]);

function handleClose() {
  modalStore.visible = false;
}

onMounted(async () => {
  inventory.value = await getInventoryByShipmentID(
    modalStore.additionalData.shipmentID
  );
});
</script>

<style scoped lang="scss">
.modalContent {
  @apply w-full;
}
</style>
