<template>
  <div>
    <div class="flex items-center justify-center gap-2">
      <div>
        <div class="flex items-center">
          <StudIcon></StudIcon>
          {{ coins }}
        </div>
        <RouterLink
          class="hover:text-primary-300 text-sm plausible-event-name=Go+to+Studs"
          to="/buy-coins"
          >Buy Studs</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { supabase } from "../../services/SupabaseService";
import { AuthState, useUserStore } from "../../stores/UserStore";
import { useLiveCoins } from "../../composable/useLiveCoins";
import { getAdminStatus } from "../../services/userService";
import stud from "../../assets/stud.svg";
import { PhGearSix, PhPerson, PhSignOut, PhCoins } from "@phosphor-icons/vue";

const userStore = useUserStore();
const coins = useLiveCoins();
const isAdmin = ref(false);

onMounted(async () => {
  if (userStore.authState == AuthState.SignedIn) {
    isAdmin.value = await getAdminStatus();
  }
});
</script>

<style scoped lang="scss"></style>
