<template>
  <div class="h-[90vh] w-full flex items-center justify-center">
    <div
      class="border rounded-lg border-secondary-300 bg-secondary-400 shadow p-4 w-[20rem]"
    >
      <h1 class="text-3xl py-2">Signup</h1>
      <form
        @submit.prevent="signUpCall"
        class="flex flex-col gap-4"
        v-if="!sendRef"
      >
        <div class="flex flex-col gap-2">
          <label for="username">Username</label>
          <InputText
            id="username"
            v-model="signupCreds.username"
            variant="filled"
            :invalid="!isValid"
            @input="isValid = true"
          />
        </div>

        <div class="flex flex-col gap-2">
          <label for="email">Email</label>
          <InputText
            id="email"
            v-model="signupCreds.email"
            variant="filled"
            :invalid="!isValid"
            @input="isValid = true"
          />
        </div>
        <div class="flex flex-col gap-2">
          <label for="password">Password</label>
          <Password
            id="password"
            v-model="signupCreds.password"
            :feedback="false"
            variant="filled"
            :invalid="!isValid"
            @input="isValid = true"
            pt:input:root:class="grow"
          />
        </div>
        <div class="">
          <InputSwitch
            v-model="signupCreds.terms"
            :invalid="!termsAndServiceValid"
            @input="termsAndServiceValid = true"
          ></InputSwitch>
          <label class="ml-2"
            >I agree to the Terms of Service and Privacy Policy</label
          >
        </div>
        <div
          class="text-red-400"
          v-if="errorMSG && (!termsAndServiceValid || !isValid)"
        >
          {{ errorMSG }}
        </div>
        <button class="bg-primary-400 rounded-lg px-4 py-2">Signup</button>
      </form>
      <div v-else>
        <p>signup complete</p>
        <p>a verification email has been send to {{ signupCreds.email }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import RBInput from "../../components/inputs/RBInput.vue";
import { signup } from "../../services/userService";

const signupCreds = ref({
  email: "",
  password: "",
  username: "",
  terms: false,
});

const termsAndServiceValid = ref(true);

const isValid = ref(true);

const errorMSG = ref("");

const sendRef = ref(false);

async function signUpCall() {
  if (!signupCreds.value.terms) {
    errorMSG.value = "You must agree to the terms and conditions";
    termsAndServiceValid.value = false;
    return;
  }
  const creds = signupCreds.value;
  try {
    const resp = await signup(creds.username, creds.email, creds.password);
    if (resp.error) {
      errorMSG.value = resp.error.message;
      isValid.value = false;
      return;
    }
  } catch (err) {
    isValid.value = false;
    return;
  }
  sendRef.value = true;
}
</script>

<style scoped>
.my-panel {
  width: 100%;
}
</style>
