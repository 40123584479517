<template>
  <div
    class="border rounded-lg border-secondary-500 p-4 bg-secondary-200 flex flex-col gap-4"
  >
    <h1 class="text-2xl">Coin Converter</h1>
    <div class="flex flex-col gap-2">
      <RBInput v-model="dollarCompare" label="Dollars"></RBInput>
      <div class="flex justify-between items-center">
        <div>
          <div>Lowest</div>
          <div class="flex items-center gap-1">
            <StudIcon></StudIcon>{{ Math.ceil(computedValues.lowest) }}
          </div>
        </div>
        <div>
          <div>Middle</div>
          <div class="flex items-center gap-1">
            <StudIcon></StudIcon>{{ Math.ceil(computedValues.middle) }}
          </div>
        </div>
        <div>
          <div>Highest</div>
          <div class="flex items-center gap-1">
            <StudIcon></StudIcon>{{ Math.ceil(computedValues.highest) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { getAllCoinPackages } from "../../services/SupabaseService";
import { CoinPackage } from "../../model/supabase";
import { PhCoins } from "@phosphor-icons/vue";
import RBInput from "../inputs/RBInput.vue";
import StudIcon from "../general/StudIcon.vue";

const packagesRef = ref<CoinPackage[]>([]);
const dollarCompare = ref(170);

const computedValues = computed(() => {
  if (packagesRef.value.length === 0)
    return {
      lowest: 0,
      middle: 0,
      highest: 0,
    };

  const lowest = packagesRef.value[0];
  const middle = packagesRef.value[Math.floor(packagesRef.value.length / 2)];
  const highest = packagesRef.value[packagesRef.value.length - 1];

  return {
    lowest: convertCoinsToDollar(lowest) * dollarCompare.value,
    middle: convertCoinsToDollar(middle) * dollarCompare.value,
    highest: convertCoinsToDollar(highest) * dollarCompare.value,
  };
});

function convertCoinsToDollar(coinPackage: CoinPackage) {
  return coinPackage.coins / coinPackage.dollar_price;
}

onMounted(async () => {
  const packages = await getAllCoinPackages();
  console.log(packages);
  packagesRef.value = packages.sort((a, b) => a.coins - b.coins);
});
</script>

<style scoped lang="scss"></style>
