<template>
  <div
    class="border rounded-lg border-secondary-500 p-4 bg-secondary-200 flex flex-col gap-4"
  >
    <h1>Numbers</h1>
    <div class="flex gap-4 justify-evenly">
      <div class="entry">
        <PhUser size="30"></PhUser>
        <div>Live Users</div>
        <div>{{ liveUsers }}</div>
      </div>
      <div class="entry">
        <PhUser size="30"></PhUser>
        <div>Total Users</div>
        <div>{{ userAmount }}</div>
      </div>
      <div class="entry">
        <PhTreasureChest size="30"></PhTreasureChest>
        <div>Boxes Opened</div>
        <div>{{ casesOpened }}</div>
      </div>
      <div class="entry">
        <PhCurrencyDollar size="30"></PhCurrencyDollar>
        <div>Studs Revenue Today</div>
        <div class="flex justify-center items-center">
          <StudIcon></StudIcon>{{ useFormatedNumber(purchasedCredits) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { PhUser, PhTreasureChest, PhCurrencyDollar } from "@phosphor-icons/vue";
import {
  getAllCoins,
  getAllOpenBoxes,
  getUserAmount,
  supabase,
} from "../../services/SupabaseService";
import { useUserTracking } from "../../composable/useUserTracking";
import StudIcon from "../general/StudIcon.vue";
import { useFormatedNumber } from "../../composable/useFormatedNumber";

const casesOpened = ref(0);
const userAmount = ref(0);
const purchasedCredits = ref(0);
const userTrackingChannel = useUserTracking();

const liveUsers = ref(0);

async function getCoinsFromToday() {
  const req = await supabase.rpc("get_open_boxes_prices_sum");
  const data = req.data ?? 0;
  return data;
}

onMounted(async () => {
  const dataPromises = [];
  dataPromises.push(getCoinsFromToday());
  dataPromises.push(getAllOpenBoxes());
  dataPromises.push(getUserAmount());
  const data = await Promise.all(dataPromises);

  purchasedCredits.value = data[0];
  casesOpened.value = data[1];
  userAmount.value = data[2];

  const state = userTrackingChannel!.presenceState();
  liveUsers.value = Object.keys(state).length;

  userTrackingChannel?.on(
    "presence",
    {
      event: "sync",
    },
    () => {
      const state = userTrackingChannel.presenceState();
      liveUsers.value = Object.keys(state).length;
    }
  );
});
</script>

<style scoped>
.entry {
  @apply flex flex-col items-center gap-2;
}
</style>
