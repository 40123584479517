<template>
  <div class="flex flex-col w-full gap-4">
    <h2>Limits</h2>
    <div class="flex-col flex gap-2">
      <div class="">
        <h3>Limit by Opening Amount</h3>
        <div class="flex gap-4 items-center">
          <InputText
            variant="filled"
            class="grow"
            :model-value="`${limits.openingAmount}`"
            @input="updateAmount"
          ></InputText>
        </div>
      </div>
      <div>
        <h3>Limit by Specific User Open Amount</h3>
        <div class="flex gap-4 items-center">
          <InputText
            variant="filled"
            class="grow"
            :model-value="`${limits.specificUserOpeningAmount}`"
            @input="updateSpecific"
          ></InputText>
        </div>
      </div>
      <div>
        <h3>Limit by Item Pulled</h3>
        <sub class="flex items-center gap-2 mb-3"
          >press the <PhGauge></PhGauge> button</sub
        >
        <div class="flex gap-4 items-center">
          <div class="flex flex-col w-full gap-3">
            <FloatLabel class="flex" v-for="item of limits.itemPulled">
              <InputText
                class="grow"
                variant="filled"
                :model-value="`${item.amount}`"
                @input="updateItemPulledNumber(item.name, $event)"
              ></InputText>
              <label>{{
                winnables.find((w) => w.id == item.name)?.name
              }}</label>
            </FloatLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhGauge } from "@phosphor-icons/vue";
import { Winnable } from "../../../model/supabase";
import { InputNumberInputEvent } from "primevue/inputnumber";

type Props = {
  limits: {
    openingAmount: number;
    specificUserOpeningAmount: number;
    itemPulled: { name: string; amount: number }[];
  };
  winnables: Winnable[];
};

const props = defineProps<Props>();
const emits = defineEmits(["update:limits"]);

function updateItemPulledNumber(name: string, inputEvent: Event) {
  const target = inputEvent.target as HTMLInputElement;

  const amount = parseInt(target!.value);
  const index = props.limits.itemPulled.findIndex((item) => item.name == name);
  if (index == -1) return;

  const newLimits = { ...props.limits };
  newLimits.itemPulled[index].amount = amount;
  emits("update:limits", newLimits);
}

function updateAmount(inputEvent: Event) {
  const target = inputEvent.target as HTMLInputElement;
  const amount = parseInt(target!.value);
  const newLimits = { ...props.limits };
  newLimits.openingAmount = amount;
  emits("update:limits", newLimits);
}

function updateSpecific(inputEvent: Event) {
  const target = inputEvent.target as HTMLInputElement;
  const amount = parseInt(target!.value) || 0;
  const newLimits = { ...props.limits };
  newLimits.specificUserOpeningAmount = amount;
  emits("update:limits", newLimits);
}
</script>

<style scoped lang="scss"></style>
