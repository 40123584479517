<template>
  <div class="modal p-4 flex flex-col gap-4 justify-between">
    <h1>Win Percentages</h1>
    <div class="flex flex-col justify-center items-center gap-4">
      <div class="w-full">
        <h2 class="text-xl">Settings</h2>
        <div class="flex gap-4">
          <div class="w-full flex flex-col gap-2">
            <div class="flex flex-col">
              <label> Calculating Price in studs</label>
              <InputText variant="filled" v-model="calcPrice"></InputText>
            </div>

            <div>
              <label>Percentage distro</label>
              <div class="flex gap-2">
                <input
                  v-model="linePercentage"
                  type="range"
                  step="1"
                  min="0"
                  max="100"
                  class="w-full"
                />
                <span>{{ linePercentage }}</span>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col items-center justify-center">
            <div class="flex gap-1">
              The users have a
              <span class="text-primary-400">{{ linePercentage }}% </span>chance
              to pull something under
              <span class="text-primary-400"> ${{ calcPrice / 1000 }}</span
              >.
            </div>
            <div class="flex gap-1">
              and a
              <span class="text-primary-400">{{ 100 - linePercentage }}%</span>
              chance to pull something over
              <span class="text-primary-400"> ${{ calcPrice / 1000 }}</span
              >.
            </div>
          </div>
        </div>
      </div>

      <div class="flex overflow-auto gap-4 w-full h-[15rem]">
        <div
          class="shrink-0 relative"
          v-for="winnable in higherThanPriceWinnables"
        >
          <WinnableLot :winnable="winnable" />
          <div class="absolute inset-0 flex justify-end items-end">
            {{ (computedChances[winnable.id] / 1000).toFixed(3) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2 justify-end">
      <button class="px-4 py-2 bg-primary-300 rounded-lg" @click="handleSave">
        Save</button
      ><button
        class="px-4 py-2 border border-secondary-400 rounded-lg"
        @click="handleClose"
      >
        Abort
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhCoins } from "@phosphor-icons/vue";
import { Winnable } from "../../../model/supabase";
import { useModalStore } from "../../../stores/ModalStore";
import { ref } from "vue";
import { computed } from "vue";
import { useWinnableWeight } from "../../../composable/useWinnableWeight";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";

const lineMargin = ref("0px");

const modalStore = useModalStore();
const linePercentage = ref(100 - modalStore.additionalData.loseChance);

const calcPrice = ref(modalStore.additionalData.price);

const winnables = modalStore.additionalData.winnables as Winnable[];

const computedChances = computed(() => {
  return useWinnableWeight({
    winnables: winnables,
    loseChance: 100 - linePercentage.value,
    price: parseInt(calcPrice.value),
  });
});

const higherThanPriceWinnables = computed(() => {
  return winnables
    .filter((winnable) => winnable.estimated_price > parseInt(calcPrice.value))
    .sort((a, b) => b.estimated_price - a.estimated_price);
});

function handleSave() {
  const chances: Record<string, number> = {};
  for (const entry of Object.entries(computedChances.value)) {
    chances[entry[0]] = entry[1] / 1000;
  }

  modalStore.additionalData.chances = chances;
  modalStore.visible = false;
}

function handleClose() {
  modalStore.visible = false;
}
</script>

<style scoped lang="scss">
.modal {
  width: 70%;
  height: 60%;
  background-color: var(--tile);
  @apply rounded-lg flex bg-secondary-300 border border-secondary-500;
}

.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.line {
  margin-left: v-bind(lineMargin);
}
</style>
