<template>
  <div
    class="flex flex-col items-center p-2 border-t-2 border-l-2 rounded-tl-lg border-secondary-200 pointer-events-auto"
  >
    <div>Cost</div>
    <div>
      <div class="text-lg flex justify-end items-center gap-1">
        <StudIcon></StudIcon>
        <div class="text-end">{{ boxWrapper.box.price }}</div>
      </div>
      <div
        class="flex items-center justify-end gap-1 text-primary-300"
        v-if="showShipping"
      >
        <PhTruck></PhTruck>
        <div class="text-end">{{ isInternational ? 20000 : 7000 }}</div>
      </div>

      <button
        class="px-2 py-1 bg-primary-300 rounded-lg text-sm"
        v-if="!hideBlindOpen"
        @click="handleBlindOpen"
      >
        Blind Open
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  PhArrowBendDoubleUpLeft,
  PhArrowsClockwise,
  PhCoins,
  PhTruck,
} from "@phosphor-icons/vue";
import { BoxWrapper } from "../../classes/BoxWrapper";
import StudIcon from "../general/StudIcon.vue";

type Props = {
  boxWrapper: BoxWrapper;
  showShipping: boolean;
  isInternational: boolean;
  hideBlindOpen: boolean;
};

const props = defineProps<Props>();
const emits = defineEmits(["blindOpen"]);

function handleBlindOpen() {
  emits("blindOpen");
}
</script>

<style scoped lang="scss"></style>
