import { onMounted, onUnmounted, ref } from "vue";

export function useMediaQuery(query: string) {
  const mediaQuery = ref(window.matchMedia(query).matches);
  const updater = () => {
    mediaQuery.value = window.matchMedia(query).matches;
  };

  onMounted(() => {
    window.addEventListener("resize", updater);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", updater);
  });
  return mediaQuery;
}
