import { getAdminStatus } from "../services/userService";
import { useUserStore } from "../stores/UserStore";

let isAdmin: boolean | undefined;

export async function useIsAdmin(userID: string) {
  if (isAdmin !== undefined) return isAdmin;

  isAdmin = await getAdminStatus(userID);
  return isAdmin;
}
