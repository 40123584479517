<template>
  <div class="flex flex-col mx-2 lg:mx-0 w-full">
    <HowItWorks></HowItWorks>
    <CoverScreenBox v-if="firstBox" :box="firstBox"></CoverScreenBox>
    <div class="py-2 border-t border-secondary-200">
      <h1 class="text-2xl py-2 w-full">Best Sellers</h1>
      <Carousel
        :key="caluclatedAmount"
        :value="shownBoxes"
        :numVisible="caluclatedAmount"
        :numScroll="caluclatedAmount"
        :autoplayInterval="5000"
      >
        <template #item="slotProps">
          <CarouselChestSlot :box="slotProps.data" />
        </template>
      </Carousel>

      <div class="container mx-auto"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import LootBox from "../components/Boxes/LootBox.vue";
import {
  getBoxes,
  supabase,
  getRecomendedBoxes,
  getNewestBox,
} from "../services/SupabaseService";
import { Box } from "../model/supabase";
import boxImage from "../assets/chest.png";
import HowItWorks from "../components/Home/HowItWorks.vue";
import CoverScreenBox from "../components/Home/CoverScreenBox.vue";
import CarouselChestSlot from "../components/general/CarouselChestSlot.vue";
import { PhCoins } from "@phosphor-icons/vue";
import { useMediaQuery } from "../composable/useMediaQuery";

const boxes = ref<Box[]>([]);
const firstBox = ref<Box>();

const isPhone = useMediaQuery("(max-width: 1280px)");

const caluclatedAmount = computed(() => {
  return isPhone.value ? 1 : 3;
});

const shownBoxes = computed(() => {
  if (!boxes.value.length) return [];
  const copiedBoxes = [...boxes.value];
  const amount = isPhone.value ? 3 : 6;
  return copiedBoxes.slice(0, amount);
});

onMounted(async () => {
  const reqCollection = await Promise.all([
    getRecomendedBoxes(),
    getNewestBox(),
  ]);

  boxes.value = reqCollection[0];
  firstBox.value = reqCollection[1];
});
</script>

<style scoped lang="scss"></style>
