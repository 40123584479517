import { Box, Box_Winnables, Winnable } from "../model/supabase";
import { openBox } from "../services/SupabaseService";

export class BoxWrapper {
  box: Box;
  winnables: Winnable[];
  weighMap: Record<string, number> = {};
  boxWinnables: Box_Winnables[];
  constructor(boxWinnable: Box_Winnables[]) {
    this.box = boxWinnable[0].Boxes;
    this.winnables = boxWinnable
      .map((winnable) => winnable.Winnables)
      .sort((a, b) => b.estimated_price - a.estimated_price);
    this.weighMap = {};
    this.boxWinnables = boxWinnable;
    boxWinnable.forEach((item) => {
      this.weighMap[item.Winnables.id] = item.weight;
    });
  }

  getWinnableByID(winnableID: string) {
    return this.winnables.find((winnable) => winnable.id === winnableID);
  }

  getWinnableWeight(winnableID: string) {
    return this.weighMap[winnableID];
  }

  getCalculatedRarity(winnableID: string) {
    const copiedArray = [...this.winnables];
    copiedArray.sort((a, b) => b.estimated_price - a.estimated_price);
    const index = copiedArray.findIndex((w) => w.id === winnableID);
    const normalisedRarityIndex = copiedArray.length / 7;
    return 7 - Math.round(index / normalisedRarityIndex);
  }

  async openBox(blind?: boolean) {
    return openBox(this.box.id, blind);
  }
}
