import { Product } from "shopify-admin-api-typings";
import { supabase } from "./SupabaseService";

export async function getShopifyItems(pageInfo?: string, collection: string) {
  const resp = await supabase.functions.invoke("pullItemFromShopify", {
    body: {
      page_info: pageInfo,
      colletion_id: collection,
    },
  });
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return resp.data.message;
}
