import { Winnable } from "../model/supabase";

export function useWinnableWeight(input: {
  winnables: Winnable[];
  price: number;
  loseChance: number;
}) {
  const { winnables, price, loseChance } = input;

  const weight: Record<string, number> = {};

  const underPriceWinnables = winnables.filter(
    (w) => w.estimated_price < price
  );

  const totalUnderPrice = underPriceWinnables.reduce(
    (p, c) => p + c.estimated_price,
    0
  );

  const firstDiv = (100 - loseChance) * 1000;
  let totalWeight = 0;

  for (const winnable of underPriceWinnables) {
    const weighFactor = winnable.estimated_price / totalUnderPrice;

    weight[winnable.id] = firstDiv / weighFactor;
    totalWeight += weight[winnable.id];
  }

  for (const winnable of underPriceWinnables) {
    weight[winnable.id] *= firstDiv / totalWeight;
  }

  const overPriceWinnables = winnables.filter((w) => {
    return w.estimated_price >= price;
  });

  let totalOverPrice = overPriceWinnables.reduce(
    (p, c) => p + c.estimated_price,
    0
  );

  const dividend = loseChance * 1000;

  totalWeight = 0;

  for (const winnable of overPriceWinnables) {
    const weighFactor = winnable.estimated_price / totalOverPrice; // Inverting the weighFactor calculation

    weight[winnable.id] = dividend / weighFactor;
    totalWeight += weight[winnable.id];
  }

  // Normalize weights
  for (const winnable of overPriceWinnables) {
    weight[winnable.id] *= dividend / totalWeight;
  }

  console.log(weight);
  return weight;
}
