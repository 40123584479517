<template>
  <div
    class="h-full flex flex-col items-center justify-center gap-4 bg-secondary-400"
  >
    <RBInput v-model="collectionID" label="Collection ID"></RBInput>
    <button
      @click="handleImport"
      class="px-4 py-2 rounded-lg bg-primary-300 flex justify-center w-40 h-8 items-center"
    >
      <span v-if="!loading">Import now</span>
      <PhSpinner v-else class="animate-spin"></PhSpinner>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import RBInput from "../../inputs/RBInput.vue";
import { supabase } from "../../../services/SupabaseService";
import { Winnable } from "../../../model/supabase";
import { useModalStore } from "../../../stores/ModalStore";
import { PhSpinner } from "@phosphor-icons/vue";

const collectionID = ref("");
const loading = ref(false);
async function handleImport() {
  loading.value = true;
  const importWinnables = await supabase.functions.invoke(
    "importFromShopifyByCollection",
    {
      body: {
        collection_id: collectionID.value,
      },
    }
  );
  const importWinnable = importWinnables.data.winnables as Winnable[];
  const modalStore = useModalStore();

  loading.value = false;
  modalStore.returnWinnable = importWinnable;
  modalStore.visible = false;
}
</script>

<style scoped lang="scss"></style>
