<template>
  <div
    class="border rounded-lg border-secondary-500 p-4 bg-secondary-200 flex flex-col gap-4"
  >
    <h1 class="text-2xl">Leaderboard</h1>
    <div>
      <div
        v-for="entry in leaderboard"
        class="flex justify-between text-xl hover-wrapper"
      >
        <div>
          {{ entry.buyer_id }}
        </div>
        <div class="">
          <div class="flex justify-center items-center stud">
            <StudIcon></StudIcon> {{ entry.total_spent }}
          </div>
          <div class="justify-center items-center price hidden">
            ~ ${{ entry.total_spent / 1000 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, Ref } from "vue";
import { ref } from "vue";
import { supabase } from "../../services/SupabaseService";
import { useUsernameByID } from "../../composable/useUsernameByID";
import StudIcon from "../general/StudIcon.vue";
import { useFormatedNumber } from "../../composable/useFormatedNumber";

const leaderboard: Ref<
  {
    buyer_id: string;
    total_spent: number;
  }[]
> = ref([]);

onMounted(async () => {
  const req = await supabase.rpc("get_leaderboard");
  const data = req.data;
  console.log(data);
  for (let i = 0; i < data.length; i++) {
    try {
      data[i].buyer_id = await useUsernameByID(data[i].buyer_id);
    } catch (err) {}
  }

  leaderboard.value = data;
});
</script>

<style scoped lang="scss">
.hover-wrapper {
  &:hover {
    .stud {
      @apply hidden;
    }
    .price {
      @apply flex;
    }
  }
}
</style>
