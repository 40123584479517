import { BoxLimit } from "../model/supabase";
import { supabase } from "../services/SupabaseService";

const limits: BoxLimit[] = [];

export async function useLimits() {
  if (limits.length !== 0) {
    return limits;
  }
  const limitReq = await supabase.from("box_limits").select("*");
  const limitData = limitReq.data as BoxLimit[];
  limits.push(...limitData);
  return limits;
}

export async function useLimitsByBoxID(boxID: string) {
  const limits = await useLimits();
  return limits.find((limit) => limit.box_id === boxID);
}
