import { RealtimeChannel } from "@supabase/supabase-js";
import { supabase } from "../services/SupabaseService";

let channel: RealtimeChannel | null = null;

export function useUserTracking() {
  if (channel) return channel;
  channel = supabase.channel("users");
  channel.subscribe();
  channel.track({});
  return channel;
}
