<template>
  <div class="flex flex-col h-full w-full">
    <div class="p-4">
      <div class="flex justify-between items-center">
        <h2 class="text-xl">Photo</h2>
        <PhX @click="handleDelete" class="cursor-pointer" size="24"></PhX>
      </div>
      <div class="h-[20rem] flex justify-center items-center">
        <div
          class="w-52 aspect-square item flex items-center justify-center shadow-xl rounded photoDiv bg-primary-300"
          :style="{
            backgroundImage: `url(${selectedItem.image.src})`,
          }"
        ></div>
      </div>
    </div>
    <div class="bg-secondary-500 px-4 py-2 h-full flex flex-col gap-2">
      <h2 class="text-xl">Values</h2>
      <RBInput v-model="model.name" label="Name" />
      <RBInput v-model="model.price" label="Price" />
      <RBInput
        v-model="model.rarity"
        label="rarity (higher -> better)"
        type="number"
        :min="0"
        :max="10"
      />
      <button
        class="rounded-lg bg-primary-200 py-2 mt-4"
        @click="saveWinnableCall"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";
import RBInput from "../../inputs/RBInput.vue";
import { SaveableWinnable } from "../../../model/admin/SaveableWinnable";
import { saveWinnable } from "../../../services/AddService";
import { useModalStore } from "../../../stores/ModalStore";
import { Product } from "shopify-admin-api-typings";
import { PhX } from "@phosphor-icons/vue";

type Props = {
  selectedItem: Product;
};

const props = defineProps<Props>();

const emit = defineEmits(["unselectItem"]);

const model = ref({
  name: "",
  price: 0,
  rarity: 0,
});

const modalStore = useModalStore();

async function saveWinnableCall() {
  if (!model.value.name || !model.value.price || !model.value.rarity) return;
  const saveWinnableObj: SaveableWinnable = {
    name: model.value.name,
    price: model.value.price,
    shopify_id: props.selectedItem.id,
    rarity: model.value.rarity,
    //@ts-ignore
    image_src: props.selectedItem.image.src!,
  };

  const winnable = await saveWinnable(saveWinnableObj);
  modalStore.returnWinnable = winnable;
  modalStore.visible = false;
}

function handleDelete() {
  emit("unselectItem");
}
</script>

<style scoped>
.photoDiv {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
