import { RouteLocation, RouteRecord, RouteRecordRaw } from "vue-router";
import Chest from "../views/Chest.vue";
import Home from "../views/Home.vue";
import Signup from "../views/signup/Signup.vue";
import { adminGuard } from "../guards/AdminGuard";
import { useUserStore } from "../stores/UserStore";
import { useIsAdmin } from "../composable/useIsAdmin";
import Overview from "../views/admin/views/Overview.vue";
import NewBox from "../views/admin/views/NewBox.vue";

import Login from "../views/Login.vue";
import HowItWorks from "../views/HowItWorks.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/chest/:id",
    component: Chest,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/unsubscribe",
    component: () => import("../views/EmailUnsub.vue"),
  },
  // {
  //   path: "/rewards",
  //   component: () => import("../views/Rewards.vue"),
  // },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/terms-of-service",
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/privacy-policy",
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/user",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/buy-coins",
    component: () => import("../views/coins/BuyCoins.vue"),
  },
  {
    path: "/buy-coins/:id",

    component: () => import("../views/coins/BuyCoinsPackage.vue"),
  },
  {
    path: "/all-chests",
    component: () => import("../views/AllChests.vue"),
  },
  {
    path: "/signup/complete",
    component: () => import("../views/signup/SignupComplete.vue"),
  },

  {
    path: "/how-it-works",
    component: HowItWorks,
  },
  {
    path: "/send-reset-password",
    component: () => import("../views/SendResetPassword.vue"),
  },
  {
    path: "/reset-password/complete",
    component: () => import("../views/ResetPasswordComplete.vue"),
  },

  {
    path: "/admin",
    component: () => import("../views/admin/Admin.vue"),
    beforeEnter: adminGuard,
    children: [
      {
        path: "",
        component: Overview,
      },
      {
        path: "new-box",
        component: NewBox,
      },
    ],
  },
];
