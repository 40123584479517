<template>
  <Transition name="hidden">
    <div class="flex flex-col gap-1 px-4 lg:px-0" v-if="topPulls.length">
      <h1 class="text-xl">Top Pulls</h1>
      <div class="flex gap-2 overflow-auto" v-auto-animate>
        <div
          v-for="pull in topPulls"
          :key="pull.id"
          :style="{
            backgroundImage: `url(${useUrlSizing(pull.image_src)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            ...useRarityStyle(boxWrapper.getCalculatedRarity(pull.id)),
          }"
          class="aspect-square h-32 rounded-lg border text-shadow flex justify-end items-center flex-col my-2 mx-1"
        >
          <div class="text-shadow">
            {{ usernameMap[pull.id] }}
          </div>
          <div class="flex items-center gap-1">
            <StudIcon></StudIcon>
            {{ pull.estimated_price }}
          </div>
        </div>
      </div>
    </div>
    <div class="h-[170px]" v-else></div>
  </Transition>
</template>

<script setup lang="ts">
import { onMounted, Ref } from "vue";
import { ref } from "vue";
import { getTopPulls, supabase } from "../../services/SupabaseService";
import { useUrlSizing } from "../../composable/useUrlSizing";
import { useUsernameByID } from "../../composable/useUsernameByID";
import { Winnable } from "../../model/supabase";
import { useWatchOpenBoxes } from "../../composable/useWatchOpenBoxes";
import { PhCoins } from "@phosphor-icons/vue";
import { useRarityStyle } from "../../composable/useRarityStyle";
import StudIcon from "../general/StudIcon.vue";
import { BoxWrapper } from "../../classes/BoxWrapper";
type Props = {
  boxID: string;
  boxWrapper: BoxWrapper;
};

const props = defineProps<Props>();

const usernameMap: Ref<Record<string, string>> = ref({});

const topPulls = ref<Winnable[]>([]);

useWatchOpenBoxes(async (wrapper) => {
  const price = wrapper.winnable.estimated_price;

  if (topPulls.value.length < 5) {
    topPulls.value.push(wrapper.winnable);
    topPulls.value.sort((a, b) => b.estimated_price - a.estimated_price);
    usernameMap.value[wrapper.winnable.id] = await useUsernameByID(
      wrapper.openBox.buyer_id
    );
    return;
  }

  for (let i = 0; i < topPulls.value.length; i++) {
    if (price > topPulls.value[i].estimated_price) {
      topPulls.value[i] = wrapper.winnable;
      usernameMap.value[wrapper.winnable.id] = await useUsernameByID(
        wrapper.openBox.buyer_id
      );
      break;
    }
  }
});

onMounted(async () => {
  const req = await getTopPulls(props.boxID);

  const winnables = req.map((pull) => pull.Winnables!);

  for (const pull of req) {
    usernameMap.value[pull.Winnables!.id] = await useUsernameByID(
      pull.buyer_id
    );
  }

  topPulls.value = winnables;
});
</script>

<style scoped lang="scss">
.text-shadow {
  text-shadow: #000 1px 0 10px;
}

.hidden-enter-active,
.hidden-leave-active {
  position: absolute;
  transition: all 0.3s ease;
}
.hidden-enter-from,
.hidden-leave-to {
  opacity: 0;
}
</style>
