<template>
  <div class="h-[90vh] w-full flex items-center justify-center">
    <div
      class="border rounded-lg border-secondary-300 bg-secondary-400 shadow p-4 w-[20rem]"
    >
      <h1 class="text-3xl py-2">Login</h1>
      <form @submit.prevent="login" class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label for="username">Email</label>
          <InputText
            id="username"
            v-model="loginCreds.email"
            variant="filled"
            :invalid="!isValid"
            @input="isValid = true"
          />
        </div>
        <div class="flex flex-col gap-2">
          <label for="password">Password</label>
          <Password
            id="password"
            v-model="loginCreds.password"
            :feedback="false"
            variant="filled"
            :invalid="!isValid"
            @input="isValid = true"
            pt:input:root:class="grow"
          />
        </div>

        <RouterLink
          to="/send-reset-password"
          class="border border-secondary-500 text-secondary-600 rounded-lg px-4 py-2"
        >
          Forgot Password
        </RouterLink>
        <button class="bg-primary-400 rounded-lg px-4 py-2">Login</button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { loginUser } from "../services/userService";
import { useUserStore } from "../stores/UserStore";
import { getCoinsForUserID } from "../services/SupabaseService";
import { useRouter } from "vue-router";
import InputText from "primevue/inputtext";

const loginCreds = ref({
  email: "",
  password: "",
});

type Props = {
  abortLinking?: boolean;
};

const props = defineProps<Props>();
const emits = defineEmits(["login-success", "login-error"]);

const isValid = ref(true);

const router = useRouter();

async function login() {
  const { email, password } = loginCreds.value;
  let userResp;
  try {
    userResp = await loginUser(email, password);
  } catch (err) {
    isValid.value = false;
    return;
  }

  const coinst = await getCoinsForUserID(userResp!.data.user!.id);

  useUserStore().signIn(userResp!.data.user, coinst.coins);
  if (props.abortLinking) {
    emits("login-success");
    return;
  }
  router.push("/");
}
</script>

<style scoped lang="scss"></style>
