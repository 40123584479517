<template>
  <div
    class="border rounded-lg border-secondary-500 p-4 bg-secondary-200 flex flex-col gap-4 container mx-auto"
  >
    <div class="flex justify-between items-center">
      <h1 class="text-xl">New Box</h1>
      <div class="flex gap-2">
        <button
          class="border-secondary-500 border rounded-lg px-4 py-2"
          @click="back"
        >
          Back
        </button>
        <button class="bg-primary-200 px-4 py-2 rounded-lg" @click="handleSave">
          Save
        </button>
      </div>
    </div>

    <div>
      <h2>Preview</h2>
      <div class="flex justify-center">
        <LootBox :box="boxData" :main-winnable="mainWinnable"></LootBox>
      </div>
    </div>
    <div class="flex gap-4">
      <LimitsSections
        v-model:limits="limits"
        :winnables="winnables"
      ></LimitsSections>
      <div class="w-full flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <label for="name">Name</label>
          <InputText id="name" v-model="boxData.name" variant="filled" />
        </div>
        <div class="flex flex-col gap-1">
          <label for="price">Price</label>
          <InputText id="price" v-model="boxData.price" variant="filled" />
        </div>

        <div class="flex flex-col gap-1">
          <label for="limit">Limit (0 if none)</label>
          <InputText id="limit" v-model="boxData.limit" variant="filled" />
        </div>
        <div class="flex flex-col gap-1">
          <label for="chips">tags</label>
          <sub>Daily, Members, UCS, Free</sub>
          <Chips
            id="chips"
            v-model="boxData.tags"
            pt:container:class="grow"
            variant="filled"
            class="pt-2"
          ></Chips>
        </div>
      </div>
    </div>
    <div>
      <div class="flex justify-between">
        <h2 class="text-2xl">Winnables {{ percentageLeft }}</h2>
        <div class="flex gap-2">
          <button
            class="px-4 py-2 rounded-lg bg-primary-300"
            @click="handleSimulate"
          >
            Simulate Openings
          </button>
          <button
            class="px-4 py-2 rounded-lg bg-primary-300"
            @click="handlePercentageModal"
          >
            Automatic Calculation
          </button>
        </div>
      </div>

      <div class="flex gap-4 flex-wrap mt-2" v-auto-animate>
        <AddWinnable @click="handleAddWinnable"></AddWinnable>
        <div v-for="(winnable, i) in sortedWinnables" class="relative">
          <WinnableLot :key="winnable.id" :winnable="winnable"></WinnableLot>
          <div
            class="absolute inset-0 flex flex-col justify-between items-end p-2"
          >
            <div class="flex flex-col gap-2">
              <PhTrash
                size="18"
                class="hover:fill-primary-200 cursor-pointer"
                @click="handleRemoveWinnable(i)"
              ></PhTrash>
              <PhGauge
                class="hover:fill-primary-200 cursor-pointer"
                size="18"
                v-if="!checkItemPulled(winnable.id)"
                @click="addItemPulled(winnable.id)"
              ></PhGauge>
              <PhX
                v-else
                class="hover:fill-primary-200 cursor-pointer"
                size="18"
                @click="removeItemPulled(winnable.id)"
              ></PhX>
            </div>
            <div class="text-shadow">
              <input
                class="w-24"
                type="number"
                min="0"
                max="100"
                v-model="percentages[winnable.id]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref } from "vue";
import RBInput from "../../../components/inputs/RBInput.vue";
import LootBox from "../../../components/Boxes/LootBox.vue";
import { Box, Winnable } from "../../../model/supabase";
import AddWinnable from "../../../components/admin/NewBox/AddWinnable.vue";
import {
  openModal,
  openPercentageModal,
  openSimulateBoxOpenModal,
} from "../../../stores/ModalStore";
import WinnableLot from "../../../components/admin/NewBox/WinnableLot.vue";
import { PhTrash, PhGauge, PhX } from "@phosphor-icons/vue";
import { saveBox } from "../../../services/AddService";
import { useRoute, useRouter } from "vue-router";
import { supabase } from "../../../services/SupabaseService";
import {
  getBoxWinnables,
  getBoxLimitByID,
} from "../../../services/SupabaseService";
import { BoxWrapper } from "../../../classes/BoxWrapper";
import { IS_DEV } from "../../../constants";

type Limits = {
  openingAmount: number;
  specificUserOpeningAmount: number;
  itemPulled: { name: string; amount: number }[];
};

const limits = ref<Limits>({
  openingAmount: 0,
  specificUserOpeningAmount: 0,
  itemPulled: [],
});

const boxData: Ref<Box> = ref({
  name: "",
  created_at: Date.now().toLocaleString(),
  id: "",
  price: 1000,
  limit: 0,
  cover_winnable: "",
  archived: false,
  link_name: "",
  tags: [],
});

const percentages: Ref<Record<string, number>> = ref({});

const percentageLeft = computed(() => {
  return (
    100 - Object.values(percentages.value).reduce((a, b) => a + b, 0)
  ).toFixed(2);
});

const router = useRouter();

const winnables: Ref<Winnable[]> = ref([]);
const mainWinnable: Ref<Winnable | undefined> = ref();

const loseChance = ref(10);

const sortedWinnables = computed(() => {
  return winnables.value.sort((a, b) => a.estimated_price - b.estimated_price);
});

function checkItemPulled(id: string) {
  return limits.value.itemPulled.some((item) => item.name === id);
}

function addItemPulled(id: string) {
  limits.value.itemPulled.push({ name: id, amount: 1 });
}

function removeItemPulled(id: string) {
  limits.value.itemPulled = limits.value.itemPulled.filter(
    (item) => item.name !== id
  );
}

async function handleAddWinnable() {
  const winnable = await openModal();
  winnables.value.push(...winnable);
}

function handleRemoveWinnable(i: number) {
  const deleted = winnables.value.splice(i, 1);
  delete percentages.value[deleted[0].id];
}

function handleMainWinnable(i: number) {
  mainWinnable.value = winnables.value[i];
}

async function handlePercentageModal() {
  const percentageReq = await openPercentageModal(
    winnables.value,
    boxData.value.price,
    loseChance.value
  );

  percentages.value = percentageReq ? percentageReq : percentages.value;

  console.log(loseChance.value);
}

async function handleSimulate() {
  const weighmap: Record<string, number> = {};
  for (const w of Object.keys(percentages.value)) {
    weighmap[w] = 100000 * (percentages.value[w] / 100);
  }

  await openSimulateBoxOpenModal(
    winnables.value,
    weighmap,
    boxData.value.price
  );
}

async function handleSave() {
  if (!mainWinnable.value) {
    boxData.value.cover_winnable = winnables.value[0].id;
  } else {
    boxData.value.cover_winnable = mainWinnable.value.id;
  }

  if (percentageLeft.value != 0) {
    return;
  }

  const weighmap: Record<string, number> = {};
  for (const w of Object.keys(percentages.value)) {
    weighmap[w] = 100000 * (percentages.value[w] / 100);
  }

  console.log(weighmap);

  await saveBox(boxData.value, winnables.value, weighmap, limits.value);

  if (!boxData.value.id && !IS_DEV) {
    await supabase.functions.invoke("sendNewestBoxEmail");
  }

  router.push("/admin");
}

function calculateLoseChance(weighMap: Record<string, number>) {
  const higherThanPriceWinnables = winnables.value.filter(
    (w) => w.estimated_price >= boxData.value.price
  );

  let weightPercentages = 0;

  for (const w of higherThanPriceWinnables) {
    weightPercentages += weighMap[w.id];
  }

  return weightPercentages;
}

function back() {
  router.push("/admin");
}
const route = useRoute();

onMounted(async () => {
  if (route.query.id) {
    const boxWinnables = await getBoxWinnables(route.query.id as string);
    const boxWrappers = new BoxWrapper(boxWinnables);

    boxData.value = boxWrappers.box;
    winnables.value = boxWrappers.winnables;

    for (const w of boxWrappers.winnables) {
      const preciseNumber = (boxWrappers.weighMap[w.id] / 1000).toPrecision(5);
      percentages.value[w.id] = parseFloat(preciseNumber);
    }

    const limitsReq = await getBoxLimitByID(route.query.id as string);
    if (!limitsReq) return;
    limits.value.itemPulled = limitsReq.item_pulled;
    limits.value.openingAmount = limitsReq.opening_amount;
    limits.value.specificUserOpeningAmount =
      limitsReq.specific_user_opening_amount;
  }
});
</script>

<style scoped lang="scss">
.text-shadow {
  text-shadow: #000 1px 0 5px;
}
</style>
