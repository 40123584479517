import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from "vue-router";
import { routes } from "./router/Router";
import { createPinia } from "pinia";
import { SUPABASE_URL } from "./constants";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import PrimeVue from "primevue/config";
export const pinia = createPinia();

import "primevue/resources/themes/mdc-dark-deeppurple/theme.css";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(pinia)
  .use(autoAnimatePlugin)
  .use(router)
  .use(PrimeVue)
  .mount("#app");
