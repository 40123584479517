import { supabase } from "../services/SupabaseService";

const usernameMap: Record<string, string> = {};

export async function useUsernameByID(userid: string) {
  if (usernameMap[userid]) {
    return usernameMap[userid];
  }
  const usernameReq = await supabase.functions.invoke("getUsernameById", {
    body: {
      userid,
    },
  });

  if (usernameReq.error) {
    console.log(usernameReq.error);
  }
  const username = usernameReq.data.username;
  usernameMap[userid] = username;
  return username;
}
