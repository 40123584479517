import { createClient } from "@supabase/supabase-js";
import { SUPABASE_ANON_KEY, SUPABASE_URL } from "../constants";
import {
  Box,
  Box_Winnables,
  BoxLimit,
  CoinPackage,
  Coins,
  Inventory,
  OpenBoxes,
  PaymentIntent,
  Shipment,
  Winnable,
} from "../model/supabase";
import { BoxWrapper } from "../classes/BoxWrapper";

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

export async function getBoxes(archived?: boolean | undefined) {
  let req;
  if (archived === undefined) {
    req = await supabase.from("Boxes").select("*");
  } else {
    req = await supabase.from("Boxes").select("*").is("archived", archived);
  }

  if (req.error) throw new Error(req.error.message);
  return req.data as Box[];
}

export async function getRecomendedBoxes() {
  const recomendedReq = await supabase
    .from("recomended_boxes")
    .select("*, Boxes(*)");
  if (recomendedReq.error) throw new Error(recomendedReq.error.message);
  const recommendedBoxes = recomendedReq.data;
  recommendedBoxes.sort((a, b) => b.total_opens - a.total_opens);

  return recommendedBoxes
    .map((regBox) => regBox.Boxes as Box)
    .filter((e) => e !== null && e.archived === false);
}

export async function getNewestBox() {
  const newestReq = await supabase
    .from("Boxes")
    .select("*")
    .order("created_at", { ascending: false })
    .limit(1);
  if (newestReq.error) throw new Error(newestReq.error.message);
  return newestReq.data[0] as Box;
}

export async function openBox(boxID: string, blind?: boolean) {
  const user = await supabase.auth.getUser();

  const resp = await supabase.functions.invoke("openBox", {
    body: {
      box_id: boxID,
      user_id: user.data.user?.id,
      open_blind: !!blind,
    },
  });
  return resp.data.winnable as Winnable["id"];
}

export async function getBoxByID(boxID: string) {
  const req = await supabase.from("Boxes").select("*").eq("id", boxID);
  if (req.error) throw new Error(req.error.message);
  return req.data[0] as Box;
}

export async function getInventory() {
  const user = await supabase.auth.getUser();

  const resp = await supabase
    .from("Inventory")
    .select("*, open_boxes(*, Winnables(*))")
    .eq("buyer_id", user.data.user?.id)
    .is("shipment_id", null);
  if (resp.error) throw new Error(resp.error.message);
  const inventory = resp.data;

  return inventory as Inventory[];
}

export async function getWinnablesByBoxID(boxID: string) {
  const req = await supabase
    .from("Boxes_Winnables")
    .select("*, Winnables(*), Boxes(*)")
    .eq("box_id", boxID);
  if (req.error) throw new Error(req.error.message);
  const boxes = req.data as Box_Winnables[];
  return boxes.map((box) => box.Winnables) as Winnable[];
}

export async function getAllWinnables() {
  const req = await supabase.from("Winnables").select("*");
  if (req.error) throw new Error(req.error.message);
  return req.data as Winnable[];
}

export async function getBoxWinnables(boxID: string) {
  const req = await supabase
    .from("Boxes_Winnables")
    .select("*, Winnables(*), Boxes(*)")
    .eq("box_id", boxID);
  if (req.error) throw new Error(req.error.message);
  const boxes = req.data as Box_Winnables[];
  return boxes;
}

export async function getBoxLimitByID(boxID: string) {
  const req = await supabase
    .from("box_limits")
    .select("*")
    .eq("box_id", boxID)
    .maybeSingle();
  if (req.error) throw new Error(req.error.message);
  return req.data as BoxLimit | undefined;
}

export async function getBoxOpenings() {
  const req = await supabase.rpc("get_box_openings");
  if (req.error) throw new Error(req.error.message);
  return req.data as Record<string, number>;
}

export async function getBoxWrapper(boxID: string) {
  const boxWinnables = await getBoxWinnables(boxID);
  return new BoxWrapper(boxWinnables);
}

export async function getCoinsForUserID(userID: string) {
  const req = await supabase.from("Coins").select("*").eq("buyer_id", userID);
  if (req.error) throw new Error(req.error.message);
  return req.data[0] as Coins;
}

export async function getAllCoins() {
  const req = await supabase.rpc("count_coins");
  if (req.error) throw new Error(req.error.message);
  return req.data as number;
}

export async function getAllOpenBoxes() {
  const req = await supabase.rpc("count_open_boxes");
  if (req.error) throw new Error(req.error.message);
  return req.data as number;
}

export async function getUserAmount() {
  const req = await supabase.from("customer").select("*");
  const count = req.count;
  if (req.error) throw new Error(req.error.message);
  return req.data.length as number;
}

export async function getAllCoinPackages() {
  const req = await supabase.from("coin_packages").select("*");
  if (req.error) throw new Error(req.error.message);
  return req.data as CoinPackage[];
}

export async function getPaymentHistory() {
  const user = await supabase.auth.getUser();
  const req = await supabase
    .from("payment_intents")
    .select("*, coin_packages(*)")
    .eq("buyer_id", user.data.user!.id);
  if (req.error) throw new Error(req.error.message);
  return req.data as PaymentIntent[];
}

export async function isFirstBox() {
  const user = await supabase.auth.getUser();
  const req = await supabase
    .from("Inventory")
    .select("*")
    .eq("buyer_id", user.data.user?.id)
    .is("shipment_id", null);
  if (req.error) throw new Error(req.error.message);
  const inventory = req.data;
  return inventory.length === 0;
}

export async function getNewestOpenBoxes() {
  const req = await supabase
    .from("open_boxes ")
    .select("*, Winnables(*), Boxes(*)")
    .order("created_at", { ascending: false })
    .eq("blind", false)
    .limit(30);

  if (req.error) throw new Error(req.error.message);
  return req.data as OpenBoxes[];
}

export async function getShipments() {
  const user = await supabase.auth.getUser();

  const req = await supabase
    .from("shipments")
    .select("*")
    .eq("buyer_id", user.data.user?.id);

  if (req.error) throw new Error(req.error.message);
  return req.data as Shipment[];
}

export async function getInventoryByShipmentID(shipmentID: string) {
  const user = await supabase.auth.getUser();

  console.log(shipmentID);

  const req = await supabase
    .from("Inventory")
    .select("*, open_boxes(*, Winnables(*))")
    .eq("shipment_id", shipmentID)
    .eq("buyer_id", user.data.user?.id);

  if (req.error) throw new Error(req.error.message);
  return req.data as Inventory[];
}

export async function getProfitOfAllBoxes() {
  const req = await supabase.rpc("calculate_box_profit");
  if (req.error) throw new Error(req.error.message);
  return req.data as Record<string, number>;
}

export async function getTopPulls(boxid: string) {
  const req = await supabase
    .from("open_boxes")
    .select("*, Winnables(*)")
    .eq("box_id", boxid)
    .order("Winnables(estimated_price)", {
      ascending: false,
    })
    .limit(7);

  if (req.error) throw new Error(req.error.message);
  return req.data as OpenBoxes[];
}
