<template>
  <div
    class="flex flex-col bg-secondary border-r-[1px] border-secondary-200 gap-4"
  >
    <div
      class="flex justify-between items-center w-full p-7 lg:hidden h-[7rem]"
    >
      <PhX size="30" @click="emitClose"></PhX>
      <LoginWidget></LoginWidget>
    </div>
    <nav class="flex flex-col justify-between h-full">
      <div class="flex flex-col">
        <RouterLink
          v-for="target in renderedTargets"
          :to="target.path"
          class="px-4 py-2 link flex items-center justify-center gap-4 hover:text-primary-500 hover:bg-secondary-200 text-2xl lg:text-lg w-full transition-colors duration-200"
          @click="emitClose"
        >
          <component :is="target.icon" size="24"></component>
          <span class="w-full text-start"> {{ target.label }}</span>
        </RouterLink>
      </div>
      <div
        class="flex justify-center items-center text-sm text-secondary-500 gap-2 flex-wrap m-4"
      >
        <RouterLink to="/privacy-policy">Privacy</RouterLink>
        •
        <RouterLink to="/terms-of-service">Terms</RouterLink>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import {
  PhCalendar,
  PhCurrencyDollar,
  PhGear,
  PhLayout,
  PhQuestionMark,
  PhTreasureChest,
  PhUser,
  PhX,
} from "@phosphor-icons/vue";
import { computed, ref } from "vue";
import { useIsAdmin } from "../../composable/useIsAdmin";
import logo from "../../assets/rb-logo.avif";
import { AuthState, useUserStore } from "../../stores/UserStore";
import { onMounted } from "vue";
import LoginWidget from "../navbar/LoginWidget.vue";

const isAdmin = ref(false);

const userStore = useUserStore();

const emits = defineEmits(["close"]);

const emitClose = () => {
  emits("close");
};

onMounted(async () => {
  if (userStore.authState === AuthState.SignedIn) {
    const user = userStore.user;
    isAdmin.value = await useIsAdmin(user!.id);
  }
});

userStore.$subscribe(async () => {
  if (userStore.authState === AuthState.SignedIn) {
    const user = userStore.user;
    isAdmin.value = await useIsAdmin(user!.id);
  }
});

const navigationTargets = computed(() => [
  {
    path: "/",
    label: "Overview",
    icon: PhLayout,
  },
  {
    path: "/all-chests",
    label: "All Chests",
    icon: PhTreasureChest,
  },

  // {
  //   path: "/daily-chests",
  //   label: "Daily Chests",
  //   icon: PhCalendar,
  //   dependend: isAdmin.value,
  // },
  // {
  //   path: "/rewards",
  //   label: "Rewards",
  //   icon: PhCurrencyDollar,
  //   dependend: userStore.authState === AuthState.SignedIn,
  // },
  {
    path: "/user",
    label: "User",
    icon: PhUser,
    dependend: userStore.authState === AuthState.SignedIn,
  },

  {
    path: "/admin",
    label: "Admin",
    icon: PhGear,
    dependend: isAdmin.value,
  },
  {
    path: "/faq",
    label: "FAQ",
    icon: PhQuestionMark,
  },
]);

const renderedTargets = computed(() =>
  navigationTargets.value.filter(
    (target) => target.dependend == undefined || target.dependend
  )
);
</script>

<style>
.link.router-link-exact-active {
  @apply text-primary-500;
}
</style>
