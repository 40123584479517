import { Box, Box_Winnables, OpenBoxes, Winnable } from "../model/supabase";
import { supabase } from "../services/SupabaseService";

export class NewOpenBoxWrapper {
  openBox: OpenBoxes;
  box: Box;
  winnable: Winnable;
  boxWinnable: Box_Winnables;
  isQueried: boolean;

  constructor(openBox: OpenBoxes) {
    this.openBox = openBox;
    if (this.openBox.Winnables) {
      this.winnable = this.openBox.Winnables;
    }
    if (this.openBox.Boxes) {
      this.box = this.openBox.Boxes;
    }
  }

  async getBoxAndWinnable() {
    const req = await supabase
      .from("Boxes_Winnables")
      .select("*, Boxes(*), Winnables(*)")
      .eq("winnable_id", this.openBox.winnable_id)
      .eq("box_id", this.openBox.box_id);
    if (req.error) throw new Error(req.error.message);
    this.box = req.data[0].Boxes;
    this.winnable = req.data[0].Winnables;
    this.isQueried = true;
    this.boxWinnable = req.data[0];
  }
}
