<template>
  <div class="flex flex-col justify-between h-full">
    <div class="flex bg-secondary-400 p-4 gap-4 justify-between">
      <div>
        <RBInput label="Collection ID" v-model="collectionID"></RBInput>
      </div>
      <div>
        <RBInput label="Item ID" v-model="collectionID"></RBInput>
      </div>
    </div>
    <div class="flex flex-wrap overflow-auto">
      <div
        v-for="item in paginatedItems"
        :key="item.id"
        class="w-24 bgImage"
        :style="{
          backgroundImage: `url(${item.image?.src})`,
        }"
        @click="handleEmit(item)"
      >
        {{ item.id }}
      </div>
    </div>
    <Pagination v-model="page" @update:model-value="handleUpdate"></Pagination>
  </div>
</template>

<script setup lang="ts">
import { computed, onActivated, onMounted, Ref, ref } from "vue";
import { useShopifyPagination } from "../../../composable/useShopifyPagination";
import { Product } from "shopify-admin-api-typings";
import Pagination from "./elements/Pagination.vue";
import RBInput from "../../inputs/RBInput.vue";

const paginatedItems: Ref<Product[]> = ref([]);
const paginationsGenerator = computed(() => {
  page.value = 1;
  return useShopifyPagination(10, collectionID.value);
});

const page = ref(1);
const collectionID = ref("");

const emit = defineEmits(["selectProduct"]);

onActivated(async () => {
  if (paginatedItems.value.length === 0) {
    paginatedItems.value = await paginationsGenerator.value(page.value);
  }
});

async function handleUpdate() {
  const items = await paginationsGenerator.value(page.value);
  console.log(items);
  paginatedItems.value = items;
}

function handleEmit(item: Product) {
  emit("selectProduct", item);
}
</script>

<style scoped>
.bgImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
</style>
