import { SaveableWinnable } from "../model/admin/SaveableWinnable";
import { Box, Winnable } from "../model/supabase";
import { getBoxWinnables, supabase } from "./SupabaseService";
import { decode } from "base64-arraybuffer";

export async function saveWinnable(winnable: SaveableWinnable) {
  const winnableSave = await supabase
    .from("Winnables")
    .insert({
      name: winnable.name,
      estimated_price: winnable.price,
      image_src: winnable.image_src,
      shopify_id: winnable.shopify_id,
      rarity: winnable.rarity,
    })
    .select("*")
    .single();
  const winnableObj = winnableSave.data as Winnable;
  return winnableObj;
}

export async function saveBox(
  box: Box,
  winnables: Winnable[],
  weight: Record<string, number>,
  limits: {
    openingAmount: number;
    specificUserOpeningAmount: number;
    itemPulled: { name: string; amount: number }[];
  }
) {
  const limitsSave = await supabase.from("box_limits").upsert({
    opening_amount: limits.openingAmount,
    specific_user_opening_amount: limits.specificUserOpeningAmount,
    item_pulled: limits.itemPulled,
    box_id: box.id || null,
  });
  if (box.id) {
    return _updateBox(box, winnables, weight);
  }

  const boxSave = await supabase
    .from("Boxes")
    .insert({
      name: box.name,
      price: box.price,
      limit: box.limit,
      cover_winnable: box.cover_winnable,
      tags: box.tags,
    })
    .select("*");

  const boxData = boxSave.data!;

  const boxWinnables = winnables.map((winnable) => {
    return {
      box_id: boxData[0].id,
      winnable_id: winnable.id,
      weight: weight[winnable.id],
    };
  });

  await supabase.from("Boxes_Winnables").insert(boxWinnables).select("*");

  return boxData[0] as Box;
}
async function _updateBox(
  box: Box,
  winnables: Winnable[],
  weight: Record<string, number>
) {
  const boxSave = await supabase
    .from("Boxes")
    .update({
      name: box.name,
      price: box.price,
      limit: box.limit,
      cover_winnable: box.cover_winnable,
      tags: box.tags,
    })
    .eq("id", box.id)
    .select("*");

  const boxData = boxSave.data!;

  const boxWinnablesReq = await getBoxWinnables(box.id);

  const deletedWinnables = boxWinnablesReq.filter(
    (e) => !winnables.find((w) => w.id === e.Winnables.id)
  );

  const updatedWinnables = boxWinnablesReq.filter((e) =>
    winnables.find((w) => w.id === e.Winnables.id)
  );
  
  await supabase
    .from("Boxes_Winnables")
    .delete()
    .in(
      "id",
      deletedWinnables.map((e) => e.id)
    );

  const boxWinnables = updatedWinnables.map((bW) => {
    const winnable = winnables.find((w) => w.id === bW.Winnables.id)!;
    return {
      id: bW.id,
      box_id: boxData[0].id,
      winnable_id: winnable.id,
      weight: weight[winnable.id],
    };
  });

  await supabase.from("Boxes_Winnables").upsert(boxWinnables);

  return boxData[0] as Box;
}

export async function archiveBox(boxID: string, archived: boolean) {
  const req = await supabase
    .from("Boxes")
    .update({
      archived: !archived,
    })
    .eq("id", boxID)
    .select("*");
  if (req.error) throw new Error(req.error.message);
  return req.data[0] as Box;
}
