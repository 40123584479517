<template>
  <div class="flex justify-between">
    <button :disabled="modelValueRef === 1" @click="handlePrev">
      previous
    </button>
    <div>
      {{ modelValueRef }}
    </div>
    <button @click="handleNext">next</button>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

type Props = {
  modelValue: number;
};

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const modelValueRef = computed(() => props.modelValue);

function handlePrev() {
  const newValue = modelValueRef.value - 1;
  emit("update:modelValue", newValue);
}

function handleNext() {
  const newValue = modelValueRef.value + 1;
  emit("update:modelValue", newValue);
}
</script>

<style scoped lang="scss"></style>
