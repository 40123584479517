<template>
  <RouterLink
    :to="`/chest/${box.id}`"
    class="w-64 h-fit rounded-xl p-4 flex flex-col lootBox items-center border border-secondary-300 hover:shadow-2xl hover:border-primary-600 relative"
    @mouseenter="onMouseEnter"
  >
    <div class="absolute inset-2 pointer-events-none flex justify-end z-0">
      <Tag
        v-if="newBox"
        value="New"
        severity="success"
        class="absolute"
        style="right: 5px; top: 5px"
      />
    </div>
    <div class="text-center text-xl z-10 absolute top-3 text-shadow">
      {{ box.name }} Box
    </div>

    <div class="relative h-[18rem]">
      <img :src="boxImg" alt="box" class="w-full h-full object-contain" />
    </div>

    <div
      class="flex flex-col items-center absolute inset-3 justify-end currency"
    >
      <div class="flex justify-items-center items-center text-lg currency">
        <StudIcon></StudIcon>{{ box.price }}
      </div>
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from "vue";
import boxImg from "../../assets/chest.png";
import trooper from "../../assets/trooper.png";
import { Box, Winnable } from "../../model/supabase";
import { supabase } from "../../services/SupabaseService";
import { PhCoins } from "@phosphor-icons/vue";
import snapSound from "../../assets/sounds/boxSnap.mp3";
import { useCurrency } from "../../composable/useCurrency";
import StudIcon from "../general/StudIcon.vue";

type Props = {
  box: Box;
  mainWinnable?: Winnable;
  sound?: boolean;
};
const sound = new Audio(snapSound);
sound.load();

const { currencyRef } = useCurrency();

const props = defineProps<Props>();

function onMouseEnter() {
  if (!props.sound) return;

  sound.play();
}

const newBox = computed(() => {
  const box = props.box;
  const boxDate = new Date(box.created_at);

  if (boxDate.getDate() === new Date().getDate()) {
    return true;
  }

  return false;
});
</script>

<style scoped>
.trooper {
  transition: all 0.8s ease;
}
.lootBox:hover .trooper {
  transform: translateY(-60px);
}

.reverse {
  @apply flex-col-reverse;
}

.text-shadow {
  text-shadow: #000 1px 0 5px;
}
</style>
