<template>
  <div class="modal p-4">
    <div class="flex justify-between">
      <h2 class="text-xl">Simulate Box Openings</h2>
      <button @click="handleClose"><PhX size="24"></PhX></button>
    </div>

    <div class="flex justify-center">
      <div class="flex flex-col">
        <label class="text-center">Number of Openings</label>
        <InputNumber v-model="numberOfOpenings"></InputNumber>
        <button @click="handleStart">Start</button>
      </div>
    </div>
    <div v-show="pullSet.length" class="flex justify-between py-2">
      <div>{{ pulledItems.length }} items pulled so far</div>
      <div class="flex items-center">
        {{ calculatedSum }} <StudIcon></StudIcon> Profit
      </div>
    </div>
    <div
      class="flex flex-wrap gap-2 overflow-auto justify-center"
      v-auto-animate
    >
      <div v-for="item in pullSet" class="relative">
        <WinnableLot :winnable="item" :rarity="1"></WinnableLot>
        <div class="absolute inset-0 flex justify-end items-end p-2">
          <div>
            <div>
              {{ calculatedAmounts[item.id] || 0 }}
            </div>
            <div>
              {{
                (
                  (calculatedAmounts[item.id] / pulledItems.length) *
                  100
                ).toFixed(3)
              }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref } from "vue";
import { Box_Winnables, Winnable } from "../../../model/supabase";
import { getAllWinnables } from "../../../services/SupabaseService";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";
import { useModalStore } from "../../../stores/ModalStore";
import { openBoxLogic } from "../../../services/BoxService";
import StudIcon from "../../general/StudIcon.vue";
import { PhX } from "@phosphor-icons/vue";

const modalStore = useModalStore();

const savedWinnables: Ref<Winnable[]> = ref([]);

const numberOfOpenings = ref(1);
const pulledItems = ref<Winnable[]>([]);
const bufferedBoxWinnables = computed(() => {
  const winnables = modalStore.additionalData.winnables;
  const weightMap = modalStore.additionalData.weightMap;

  const bufferedBoxWinnables: {
    winnable: Winnable;
    weight: number;
  }[] = winnables.map((winnable) => {
    return { winnable, weight: weightMap[winnable.id] };
  });
  return bufferedBoxWinnables;
});

onMounted(async () => {
  savedWinnables.value = await getAllWinnables();
});

const calculatedAmounts = computed(() => {
  return pulledItems.value.reduce((acc: Record<string, number>, curr) => {
    acc[curr.id] = (acc[curr.id] || 0) + 1;
    return acc;
  }, {});
});

const calculatedSum = computed(() => {
  const boxPrice = modalStore.additionalData.boxPrice;
  return pulledItems.value.reduce(
    (acc, curr) => acc + (boxPrice - curr.estimated_price),
    0
  );
});

const pullSet = computed(() => {
  const filteredList: Winnable[] = [];
  for (let i = 0; i < pulledItems.value.length; i++) {
    const item = pulledItems.value[i];
    const isInList = filteredList.some((w) => w.id === item.id);
    if (!isInList) {
      filteredList.push(item);
    }
  }
  return filteredList;
});

async function handleStart() {
  pulledItems.value = [];
  for (let i = 0; i < numberOfOpenings.value; i++) {
    const clonedArray = [...bufferedBoxWinnables.value].map((w) =>
      JSON.parse(JSON.stringify(w))
    );
    const pull = openBoxLogic(clonedArray);

    pulledItems.value.push(pull.winnable);
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }
}

function handleClose() {
  modalStore.visible = false;
}
</script>

<style scoped>
.modal {
  width: 70%;
  height: 60%;
  background-color: var(--tile);
  @apply rounded-lg flex flex-col bg-secondary-300 border border-secondary-500;
}
</style>
