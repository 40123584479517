const styleColors: Record<number, string> = {
  1: "#fdfefe",
  2: "#27AE60",
  3: "#7D3C98",
  4: "#f1c40f",
  5: "#D35400",
  6: "#7B241C",
  7: "#B3CA1F",
  8: "#dc2367",
  9: "#000000",
  10: "#000000",
};

export function useRarityStyle(rarity: number) {
  const color = styleColors[rarity];

  const opacityAdded = `${color}3a`;

  return {
    borderColor: color,
    boxShadow: `0px 0px 0px ${rarity}px ${opacityAdded}`,
  };
}
