<template>
  <div class="flex flex-col justify-center py-5 border-t border-secondary-200">
    <h2 class="text-2xl">Fresh In</h2>
    <div
      class="flex justify-evenly items-center gap-10 overflow-hidden hover:gap-14 transition-all duration-200 ease-in-out"
      v-if="items.length > 0"
    >
      <img
        v-if="items.length > 0"
        v-for="item in figAmounts"
        :src="useUrlSizing(items[item].image_src)"
        class="max-h-48 rounded-lg border aspect-square object-cover hidden xl:block"
        :style="{ ...useRarityStyle(6) }"
      />
      <RouterLink
        :to="`/chest/${box.id}`"
        class="w-64 rounded-xl h-72 p-4 flex flex-col items-center border hover:shadow-2xl hover:!border-primary-600 relative justify-between gap-2 text-shadow m-4 overflow-hidden"
        :style="{
          backgroundImage: `url(${useUrlSizing(items[0].image_src)})`,
          ...useRarityStyle(7),
        }"
      >
        <div class="text-xl flex flex-col gap-1 items-center justify-center">
          <div>{{ box.name }} Box</div>
        </div>

        <div
          class="absolute inset-0 gradient-up z-10 flex justify-end flex-col items-center"
        >
          <div
            class="flex justify-items-center items-center text-lg currency z-20 mb-2"
          >
            <StudIcon></StudIcon>{{ box.price }}
          </div>
        </div>
      </RouterLink>
      <img
        v-for="item in figAmounts"
        v-if="items.length > 0"
        :src="useUrlSizing(items[item + 2].image_src)"
        class="max-h-48 rounded-lg aspect-square object-cover hidden xl:block border"
        :style="{ ...useRarityStyle(6) }"
      />
    </div>
    <div
      v-else
      class="flex justify-evenly items-center gap-10 overflow-hidden hover:gap-14 transition-all duration-200 ease-in-out"
    >
      <div
        v-for="holder in figAmounts"
        class="h-48 rounded-lg border aspect-square object-cover hidden xl:block relative overflow-hidden"
      >
        <div class="skeleton">
          <span class="skeleton-block"></span>
        </div>
      </div>
      <div
        class="w-64 rounded-xl h-72 p-4 flex flex-col items-center border hover:shadow-2xl hover:!border-primary-600 relative justify-between gap-2 text-shadow m-4 overflow-hidden"
      >
        <div class="skeleton">
          <span class="skeleton-block"></span>
        </div>
      </div>
      <div
        v-for="holder in figAmounts"
        class="h-48 rounded-lg border aspect-square object-cover hidden xl:block relative overflow-hidden"
      >
        <div class="skeleton">
          <span class="skeleton-block"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Box } from "../../model/supabase";
import LootBox from "../Boxes/LootBox.vue";
import { supabase } from "../../services/SupabaseService";
import { useUrlSizing } from "../../composable/useUrlSizing";
import { useRarityStyle } from "../../composable/useRarityStyle";
import { useMediaQuery } from "../../composable/useMediaQuery";
import { computed } from "vue";
type Props = {
  box: Box;
};

const isSmall = useMediaQuery("(max-width: 1880px)");
const figAmounts = computed(() => {
  if (isSmall.value) {
    return 1;
  }
  return 2;
});
const props = defineProps<Props>();

const items = ref<
  {
    image_src: string;
  }[]
>([]);

onMounted(async () => {
  const itemsReq = await supabase.rpc("get_top_expensive_items", {
    p_box_id: props.box.id,
  });
  items.value = itemsReq.data;
});
</script>

<style scoped lang="scss">
.gradient-up {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(50, 50, 50, 0.3),
    rgba(50, 50, 50, 0)
  );
}

.skeleton :deep(span) > *,
.skeleton :deep(.skeleton-block) > * {
  @apply invisible;
}

.skeleton :deep(span:after),
.skeleton :deep(.skeleton-block:after) {
  animation-duration: 1.5s;
  animation-name: wave;
  animation-iteration-count: infinite;
  content: "";
  transform: translateX(-100%);
  @apply top-0 bottom-0 right-0 left-0 absolute bg-gradient-to-r from-transparent via-[#ffffff0a] to-transparent;
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
