<template>
  <div
    class="flex flex-col border rounded-lg p-2"
    :class="{
      'border-secondary-300': box.archived,
      'border-secondary-500': !box.archived,
    }"
  >
    <div class="flex justify-between">
      {{ box.name }}
      <div class="flex gap-2">
        <div class="flex gap-1 items-center">
          <PhTreasureChest /> {{ boxesOpening || 0 }}
          {{ box.limit ? ` / ${box.limit}` : "" }}
        </div>
        <div class="flex items-center gap-1">
          <ProfitIndicator :profit="boxProift"></ProfitIndicator>
        </div>
      </div>
    </div>
    <div class="text-sm flex justify-between" v-if="open">
      <div class="flex gap-1 items-center">
        Profit:<StudIcon></StudIcon> {{ boxProift }}
      </div>
      <div class="flex gap-2 text-lg">
        <button @click="handleEdit"><PhPencil></PhPencil></button>
        <button @click="handleArchive">
          <PhArchive v-if="!box.archived" /><PhBoxArrowUp v-else />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { Box } from "../../../model/supabase";
import ProfitIndicator from "./ProfitIndicator.vue";
import {
  PhTreasureChest,
  PhCoins,
  PhPencil,
  PhArchive,
  PhBoxArrowUp,
} from "@phosphor-icons/vue";
import StudIcon from "../../general/StudIcon.vue";

type Props = {
  box: Box;
  boxesOpening?: number;
  boxProift?: number;
  open?: boolean;
};

const props = defineProps<Props>();
const router = useRouter();
const emit = defineEmits(["archive"]);

function handleEdit() {
  router.push(`/admin/new-box?id=${props.box.id}`);
}

function handleArchive() {
  emit("archive");
}
</script>

<style scoped lang="scss"></style>
