import { Customer } from "../model/supabase";
import { supabase } from "./SupabaseService";

export function signup(username: string, email: string, password: string) {
  return supabase.auth.signUp({
    email: email,
    password: password,
    options: {
      emailRedirectTo: `${import.meta.env.VITE_LOCATION_URL}/signup/complete`,
      data: {
        username: username,
      },
    },
  });
}

export async function getAdminStatus(userID?: string): Promise<boolean> {
  const userResp = await supabase.auth.getUser();
  const user = userResp.data;

  const resp = await supabase
    .from("Admins")
    .select("*")
    .eq("user_id", userID || user?.user?.id);
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return !!resp.data.length;
}

export async function loginUser(email: string, password: string) {
  const resp = await supabase.auth.signInWithPassword({ email, password });
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return resp;
}

export async function getCustomer() {
  const user = await supabase.auth.getUser();
  const resp = await supabase
    .from("customer")
    .select("*")
    .eq("buyer_id", user.data.user?.id)
    .maybeSingle();
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return resp.data as Customer;
}

export async function sendResetPasswordEmail(email: string) {
  const resp = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${import.meta.env.VITE_LOCATION_URL}/reset-password/complete`,
  });
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return resp;
}

export async function resetPassword(password: string) {
  const resp = await supabase.auth.updateUser({
    password: password,
  });
  if (resp.error) {
    throw new Error(resp.error.message);
  }
  return resp;
}
