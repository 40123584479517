<template>
  <div class="countdown-timer">
    <p v-if="timeLeft.total > 0">
      {{ timeLeft.hours }}h : {{ timeLeft.minutes }}m : {{ timeLeft.seconds }}s
    </p>
    <p v-else></p>
  </div>
</template>

<script setup lang="ts">
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { onMounted, onUnmounted, ref } from "vue";

type Props = {
  endDate: Date;
};

const props = defineProps<Props>();

const timeLeft = ref({
  total: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
});

let timer: NodeJS.Timeout;

function calculateTimeLeft() {
  const now = new Date().getTime();
  const endDate = new Date(props.endDate).getTime();
  const difference = endDate - now;

  if (difference > 0) {
    timeLeft.value.total = difference;
    timeLeft.value.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    timeLeft.value.hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    timeLeft.value.minutes = Math.floor(
      (difference % (1000 * 60 * 60)) / (1000 * 60)
    );
    timeLeft.value.seconds = Math.floor((difference % (1000 * 60)) / 1000);
  } else {
    timeLeft.value.total = 0;
    timeLeft.value.days = 0;
    timeLeft.value.hours = 0;
    timeLeft.value.minutes = 0;
    timeLeft.value.seconds = 0;
    clearInterval(timer);
  }
}

onMounted(() => {
  calculateTimeLeft();
  timer = setInterval(calculateTimeLeft, 1000);
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<style scoped lang="scss"></style>
