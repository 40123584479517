<template>
  <div>
    <div
      class="w-64 h-[20rem] rounded-xl p-4 flex flex-col lootBox items-center border shadow-2xl border-primary-600 relative"
    >
      <div class="text-center text-xl">{{ boxWrapper.box.name }} Box</div>

      <div class="h-full flex items-center">
        <PhSpinner
          class="animate-spin duration-1000"
          size="64"
          v-if="!isDoneOpening"
        ></PhSpinner>
        <PhQuestionMark size="64" v-else></PhQuestionMark>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhSpinner, PhQuestionMark } from "@phosphor-icons/vue";
import { BoxWrapper } from "../../../classes/BoxWrapper";
import { ref } from "vue";

const loading = ref(false);

type Props = {
  boxWrapper: BoxWrapper;
  isDoneOpening: boolean;
};

const props = defineProps<Props>();
</script>

<style scoped lang="scss"></style>
