<template>
  <Transition name="modal">
    <div v-if="modalStore.visible" class="modalWrapper">
      <component :is="modalStore.component"></component>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { useModalStore } from "../../stores/ModalStore";

const modalStore = useModalStore();
</script>

<style scoped>
.modalWrapper {
  @apply absolute w-full h-full top-0 flex justify-center items-center z-30;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-enter-active,
.modal-leave-active {
  position: absolute;
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(1.2);
}
</style>
