<template>
  <div
    class="raffle-roller relative flex items-center justify-center w-full"
    :class="{ animateToggle }"
  >
    <div class="raffle-roller-holder" ref="raffleHolder">
      <div class="raffle-roller-container" ref="raffleContainer">
        <div
          v-for="(_item, i) in shownItems"
          :id="`card-${i}`"
          class="item"
          :style="`background-image:url(${useUrlSizing(_item.image_src)})`"
        ></div>
      </div>
    </div>
    <div class="absolute inset-0 flex justify-center items-center">
      <div class="border-r-2 h-full border-primary"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, Ref, ref } from "vue";
import trooper from "../../assets/trooper.png";
import { BoxWrapper } from "../../classes/BoxWrapper";
import { supabase } from "../../services/SupabaseService";
import testAudio1 from "../../assets/sounds/_1.wav";
import { useUrlSizing } from "../../composable/useUrlSizing";
import testAudio2 from "../../assets/sounds/_2.wav";

type Props = {
  boxWrapper: BoxWrapper;
  winner: string;
};

const marginL = ref("0px");

const raffleHolder: Ref<HTMLDivElement | null> = ref(null);
const raffleContainer: Ref<HTMLDivElement | null> = ref(null);

const emit = defineEmits(["animationDone"]);

const props = defineProps<Props>();

const boxWrapper: Ref<BoxWrapper> = ref(props.boxWrapper);
const animateToggle = ref(false);

onMounted(() => {
  setTimeout(() => {
    open();
  }, 100);
});

async function open() {
  const splicedArr = shownItems.value.slice(50, shownItems.value.length - 1);
  const winnerId = splicedArr.findIndex((_item) => _item.id === props.winner);

  const raffleHolderRef = raffleHolder.value!;
  const raffleContainerRef = raffleContainer.value!;
  const raffleItem = raffleContainerRef.children[0] as HTMLDivElement;
  const raffleItemWidth = raffleItem.offsetWidth;

  const realItemWidth = raffleItemWidth + 8;

  const scrollMargin =
    realItemWidth * (50 + winnerId) -
    raffleHolderRef.clientWidth / 2 +
    realItemWidth / 2;

  const audio = new Audio(testAudio1);
  audio.play();

  const animationRef = raffleContainer.value!.animate(
    [
      {
        transform: `translateX(-${scrollMargin}px)`,
      },
    ],
    {
      fill: "forwards",
      duration: 8000,
      easing: "cubic-bezier(0.25, 0.5, 0.3, 1)",
    }
  );

  animationRef.onfinish = () => {
    // Pause for a few seconds before resetting
    setTimeout(() => {
      emit("animationDone"); // Emit the event

      animationRef.cancel(); // Reset the animation
      // You can optionally add your reset logic here
    }, 300);
  };
}

const shownItems = computed(() => {
  if (!props.boxWrapper) return [];
  const newArr = new Array(boxWrapper.value.winnables.length * 10).fill(
    boxWrapper.value.winnables[0]
  );
  const test = newArr.map(
    (_item, i) =>
      boxWrapper.value.winnables[i % boxWrapper.value.winnables.length]
  );
  return test;
});

function getImageForID(id: string) {
  return supabase.storage.from("winnable_images").getPublicUrl(id + ".png").data
    .publicUrl;
}
</script>

<style scoped>
.rarity-1 {
  @apply bg-primary-200;
}
.rarity-2 {
  @apply bg-primary-300;
}
.rarity-3 {
  @apply bg-primary-400;
}
.rarity-4 {
  @apply bg-primary-500;
}

.item {
  @apply bg-primary-300 p-4 rounded-lg;
  display: inline-block;
  margin: 4px;

  aspect-ratio: 85/88;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.raffle-roller-container {
  width: 9999999999999999999px;
  max-width: 999999999999999999px;
  height: 200px;
  margin-left: v-bind(marginL);
}

.raffle-roller-holder {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 210px;
  width: 80vw;
  overflow: hidden;
}
</style>
