<template>
  <div
    class="modal w-full m-4 md:w-1/4 h-2/4 border border-secondary-300 rounded-lg bg-secondary-300 flex justify-between items-center flex-col p-4"
  >
    <div class="flex flex-col items-center">
      <h1 class="text-2xl">Are you sure?</h1>
    </div>

    <div class="text-center">
      With this action, you will close the order and you will have to pay
      shipping again on the next box.
    </div>
    <div class="flex w-full gap-4">
      <button
        class="px-4 py-2 bg-primary-300 rounded-lg w-full"
        @click="handleClose(true)"
      >
        Confirm
      </button>
      <button
        class="px-4 py-2 bg-secondary-300 border rounded-lg w-full"
        @click="handleClose(false)"
      >
        Abort
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from "../../../stores/ModalStore";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";

const modalStore = useModalStore();

function handleClose(consent: boolean) {
  modalStore.additionalData.consent = consent;
  modalStore.visible = false;
}
</script>

<style scoped lang="scss">
.modalContent {
  @apply w-full;
}
</style>
