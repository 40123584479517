import { REALTIME_LISTEN_TYPES, RealtimeChannel } from "@supabase/supabase-js";
import { getCoinsForUserID, supabase } from "../services/SupabaseService";
import { ref } from "vue";
import { AuthState, useUserStore } from "../stores/UserStore";
const coinRef = ref(0);
let channel: RealtimeChannel | undefined;
export function useLiveCoins() {
  if (channel) return coinRef;
  const getCoinsForUserIDPromise = async () => {
    const user = await supabase.auth.getUser();

    const coins = await getCoinsForUserID(user.data.user!.id);
    coinRef.value = coins.coins;
  };

  channel = supabase
    .channel("schema-db-changes")
    .on(
      //@ts-expect-error
      REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
      {
        event: "UPDATE",
        scheme: "public",
        table: "Coins",
        filter: `buyer_id=eq.${useUserStore().user?.id}`,
      },
      (payload: any) => {
        coinRef.value = payload.new.coins;
      }
    )
    .subscribe();

  getCoinsForUserIDPromise();

  return coinRef;
}
