import { RealtimeChannel } from "@supabase/supabase-js";
import { supabase } from "../services/SupabaseService";
import { NewOpenBoxWrapper } from "../classes/NewOpenBoxWrapper";
import { OpenBoxes } from "../model/supabase";
import { onMounted, onUnmounted, Ref, ref } from "vue";

class SubscribeWatcher {
  channel?: RealtimeChannel;
  subscribers: Array<(wrapper: NewOpenBoxWrapper) => void> = [];

  constructor() {}

  subscribe(callback: (wrapper: NewOpenBoxWrapper) => void) {
    if (!this.channel) {
      this._startSubscription();
    }
    const length = this.subscribers.push(callback);
    return length - 1;
  }
  unsubscribe(index: number) {
    this.subscribers.splice(index, 1);
    if (this.subscribers.length === 0) {
      this._stopSubscription();
    }
  }

  private _handleCallbacks(wrapper: NewOpenBoxWrapper) {
    this.subscribers.forEach((subscriber) => {
      subscriber(wrapper);
    });
  }

  private _startSubscription() {
    this.channel = supabase
      .channel("openBoxes")
      .on(
        "postgres_changes",
        {
          schema: "public", // Subscribes to the "public" schema in Postgres
          event: "INSERT", // Listen to all changes
          table: "open_boxes",
          filter: "blind=eq.false",
        },
        async (payload) => {
          const newData = payload.new as OpenBoxes;
          const wrapper = new NewOpenBoxWrapper(newData);
          await wrapper.getBoxAndWinnable();
          setTimeout(() => {
            this._handleCallbacks(wrapper);
          }, 10000);
        }
      )
      .subscribe();
  }

  private _stopSubscription() {
    this.channel?.unsubscribe();
    this.channel = undefined;
  }
}

const watcher = new SubscribeWatcher();

export function useWatchOpenBoxes(
  callback: (wrapper: NewOpenBoxWrapper) => void
) {
  let index: number;
  onMounted(() => {
    index = watcher.subscribe(callback);
  });
  onUnmounted(() => {
    watcher.unsubscribe(index);
  });
}

export function useWatchSpecificItems(boxID: string) {
  const itemsRef: Ref<NewOpenBoxWrapper[]> = ref([]);
  onMounted(async () => {
    const req = await supabase
      .from("open_boxes")
      .select("*")
      .eq("box_id", boxID)
      .order("created_at", { ascending: false });
    if (req.error) {
      throw new Error(req.error.message);
    }
    const data = req.data as OpenBoxes[];
    itemsRef.value = data.map((item) => new NewOpenBoxWrapper(item));
  });
  useWatchOpenBoxes((wrapper) => {
    if (wrapper.box.id === boxID) {
      itemsRef.value.push(wrapper);
    }
  });
  return itemsRef;
}
