<template>
  <component
    :is="proiftObj.icon"
    :class="proiftObj.color"
    size="20"
  ></component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PhArrowUp, PhArrowDown, PhMinus } from "@phosphor-icons/vue";

type Props = {
  profit?: number;
};
const props = defineProps<Props>();

const proiftObj = computed(() => {
  const profit = props.profit || 0;
  switch (true) {
    case profit > 0:
      return {
        color: "green",
        icon: PhArrowUp,
      };
    case profit < 0:
      return {
        color: "red",
        icon: PhArrowDown,
      };
    default:
      return {
        color: "gray",
        icon: PhMinus,
      };
  }
});
</script>

<style scoped>
.green {
  @apply text-green-500;
}
.red {
  @apply text-red-500;
}
.gray {
  @apply text-gray-500;
}
</style>
