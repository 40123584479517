import { Ref, ref } from "vue";

const currencyRef = ref(localStorage.getItem("currency") || "Coins") as Ref<
  "Coins" | "Dollar"
>;

export function useCurrency() {
  const flipCurrency = () => {
    const currency = currencyRef.value === "Coins" ? "Dollar" : "Coins";
    localStorage.setItem("currency", currency);
    currencyRef.value = currency;
  };
  return {
    currencyRef,
    flipCurrency,
  };
}
