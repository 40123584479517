<template>
  <div
    class="modal m-4 border border-secondary-300 rounded-lg bg-secondary-300 flex justify-between items-center flex-col p-4"
  >
    <div class="flex flex-col items-center h-full gap-4">
      <div class="flex justify-between items-center w-full">
        <h1 class="text-2xl">Notifications Settings</h1>
        <PhX
          size="24"
          @click="close"
          class="cursor-pointer"
          v-if="isModal"
        ></PhX>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <div class="flex gap-2 items-center">
            <InputSwitch
              v-model="notValues.promotions"
              input-id="prom"
            ></InputSwitch
            ><label for="prom">Promotions</label>
          </div>
          <div>Emails about new boxes, coupon codes or special offers</div>
        </div>

        <div class="flex flex-col">
          <div class="flex gap-2 items-center">
            <InputSwitch
              v-model="notValues.checkoutUpdates"
              input-id="check"
            ></InputSwitch
            ><label for="check">Checkout Updates</label>
          </div>
          <div>
            Emails about your inventory. This includes notifications for coming
            order closings.
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex gap-2 items-center">
            <InputSwitch
              :model-value="true"
              input-id="check"
              disabled
            ></InputSwitch
            ><label for="check">Managment</label>
          </div>
          <div>
            We may contact you to resolve technical issues with your account.
          </div>
        </div>
      </div>
      <div class="w-full flex justify-end">
        <button @click="save" class="px-4 py-2 rounded-lg bg-primary-300">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { PhX } from "@phosphor-icons/vue";
import { useModalStore } from "../../../stores/ModalStore";
import { onMounted } from "vue";
import { getCustomer } from "../../../services/userService";
import { supabase } from "../../../services/SupabaseService";
import { useRouter } from "vue-router";

type Props = {
  nonModal?: boolean;
};

const props = defineProps<Props>();

const isModal = computed(() => !props.nonModal);

const modalStore = useModalStore();

const router = useRouter();

const notValues = ref({
  promotions: true,
  checkoutUpdates: true,
});

function close() {
  modalStore.visible = false;
}

async function save() {
  await supabase.functions.invoke("notifications", {
    body: {
      notifications: {
        prom: notValues.value.promotions,
        check: notValues.value.checkoutUpdates,
      },
    },
    method: "POST",
  });

  if (!props.nonModal) {
    modalStore.visible = false;
    return;
  }
  router.push("/");
}

onMounted(async () => {
  const customer = await supabase.functions.invoke("notifications", {
    method: "GET",
  });
  const customerData = customer.data.notifications;
  notValues.value.promotions = customerData.prom;
  notValues.value.checkoutUpdates = customerData.check;
});
</script>

<style scoped lang="scss"></style>
