<template>
  <div
    class="modal lg:w-1/4 border border-secondary-300 rounded-lg bg-secondary-200 flex justify-between items-center flex-col p-4 gap-4"
  >
    <div class="flex justify-between items-center w-full">
      <h2 class="text-2xl">Blind Open</h2>
      <PhX size="24" class="cursor-pointer" @click="closeModal"></PhX>
    </div>

    <div class="text-center">
      Blind open allows you to buy a box without knowing what's inside. It will
      still use the same algorithm as the normal open. It wont show on the
      "Recently Won" section either.
    </div>

    <div class="relative">
      <BlindOpenPurchaseSection
        v-if="isOpening"
        :boxWrapper="boxWrapper"
        :isDoneOpening="isDoneOpening"
      ></BlindOpenPurchaseSection>
      <div
        class="w-64 h-fit rounded-xl p-4 flex flex-col lootBox items-center border shadow-2xl border-primary-600 relative"
        :class="{
          'border-red-500': boxPrice > coins,
        }"
        v-else
      >
        <div class="text-center text-xl">{{ boxWrapper.box.name }} Box</div>

        <div class="relative h-[18rem]">
          <img :src="boxImg" alt="box" class="w-full h-full object-contain" />
        </div>

        <div
          class="flex flex-col items-center absolute inset-3 justify-end currency"
        >
          <div
            class="flex justify-items-center flex-col items-center gap text-lg currency"
            :class="{
              'text-red-500': boxPrice > coins,
            }"
          >
            <div class="flex items-center gap-1">
              <StudIcon></StudIcon
              >{{ boxWrapper.box.price * Number(selection) }}
            </div>
            <div class="flex items-center gap-1" v-if="!hasShipping">
              <PhTruck></PhTruck> 7000
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-0 right-0">
        <div class="p-2 text-lg">{{ selection }}x</div>
      </div>
    </div>
    <div v-if="!isOpening">
      <SelectButton v-model="selection" :options="options"></SelectButton>
    </div>
    <div></div>

    <div class="flex justify-center" v-if="!isOpening">
      <button class="px-4 py-2 bg-primary-300 rounded-lg" @click="openBox">
        Purchase
      </button>
    </div>
    <div v-else class="flex justify-end gap-4 w-full">
      <button
        class="px-4 py-2 bg-primary-300 rounded-lg disabled:bg-secondary-400"
        :disabled="!isDoneOpening"
        @click="handleShipNow"
      >
        Ship Now
      </button>
      <button
        class="px-4 py-2 border-primary-300 border rounded-lg disabled:border-secondary-400"
        :disabled="!isDoneOpening"
        @click="closeModal"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { BoxWrapper } from "../../../classes/BoxWrapper";
import { useModalStore } from "../../../stores/ModalStore";
import LootBox from "../../Boxes/LootBox.vue";
import boxImg from "../../../assets/chest.png";
import { PhX, PhCoins, PhTruck } from "@phosphor-icons/vue";
import { useLiveCoins } from "../../../composable/useLiveCoins";
import { supabase } from "../../../services/SupabaseService";
import StudIcon from "../../general/StudIcon.vue";

const modalStore = useModalStore();

const selection = ref<string>("1");
const options = ["1", "2", "3", "4", "5"];
const coins = useLiveCoins();

const isOpening = ref(false);
const isDoneOpening = ref(false);

const boxWrapper = modalStore.additionalData.boxWrapper as BoxWrapper;
const hasShipping = modalStore.additionalData.hasShipping as boolean;

const boxPrice = computed(() => {
  return (
    boxWrapper.box.price * Number(selection.value) + (!hasShipping ? 7000 : 0)
  );
});

async function openBox() {
  isOpening.value = true;
  const amount = Number(selection.value);
  selection.value = "0";
  for (let i = 0; i < amount; i++) {
    selection.value = (Number(selection.value) + 1).toString();
    await boxWrapper.openBox(true);
  }
  isDoneOpening.value = true;
}

async function handleShipNow() {
  const userReq = await supabase.auth.getUser();
  const user = userReq.data.user;

  await supabase.functions.invoke("handleInventory", {
    body: {
      user_id: user!.id,
    },
  });

  modalStore.additionalData.shipped = true;
  modalStore.visible = false;
}

function closeModal() {
  modalStore.additionalData.shipped = false;
  modalStore.additionalData.bought = isOpening.value;
  modalStore.visible = false;
}
</script>

<style scoped lang="scss"></style>
