<template>
  <div class="flex flex-col overflow-auto h-full p-4 gap-4">
    <h2 class="text-xl">Winnables</h2>
    <div class="flex flex-wrap gap-4">
      <WinnableLot
        v-for="winnable in savedWinnables"
        :winnable="winnable"
        :key="winnable.id"
        @click="handleClick(winnable)"
      ></WinnableLot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { Winnable } from "../../../model/supabase";
import { getAllWinnables } from "../../../services/SupabaseService";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";
import { useModalStore } from "../../../stores/ModalStore";

const modalStore = useModalStore();

const savedWinnables: Ref<Winnable[]> = ref([]);
onMounted(async () => {
  savedWinnables.value = await getAllWinnables();
});

function handleClick(winnable: Winnable) {
  modalStore.returnWinnable = winnable;
  modalStore.visible = false;
}
</script>

<style scoped></style>
