<template>
  <div
    class="border border-secondary-300 rounded-lg m-2 p-3 hover:border-primary-400 transition-all duration-200 slot-wrapper"
  >
    <div class="mb-3">
      <div class="relative mx-auto flex gap-2 overflow-hidden">
        <img :src="boxImage" :alt="box.name" class="h-20" />

        <div class="flex items-center gap-2 image-wrapper">
          <Image
            v-for="item in shownItems"
            :src="useUrlSizing(item.image_src)"
            image-class="h-14 rounded border aspect-square object-cover "
            preview
            pt:image:loading="lazy"
          />
        </div>

        <Tag
          v-if="limit"
          value="Limited"
          severity="warning"
          class="absolute"
          style="right: 5px; top: 5px"
        />
      </div>
    </div>
    <div class="font-medium h-[3rem] text-clip overflow-hidden">
      {{ box.name }}
    </div>
    <div class="flex justify-between items-center h-10">
      <div class="mt-0 text-xl flex items-end gap-1 h-full">
        <StudIcon></StudIcon> {{ box.price }}
      </div>

      <RouterLink
        :to="`/chest/${box.id}`"
        class="button-wrapper w-0 overflow-hidden transition-all duration-200"
      >
        <button class="px-4 py-2 bg-primary-400 rounded-lg">Open</button>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Box } from "../../model/supabase";
import boxImage from "../../assets/chest.png";
import { computed, onMounted, ref } from "vue";
import { supabase } from "../../services/SupabaseService";
import { PhCoins } from "@phosphor-icons/vue";
import { useUrlSizing } from "../../composable/useUrlSizing";
import { useMediaQuery } from "../../composable/useMediaQuery";
import StudIcon from "./StudIcon.vue";
import { useLimits, useLimitsByBoxID } from "../../composable/useLimits";

type Props = {
  box: Box;
};

const isMobile = useMediaQuery("(max-width: 400px)");
const isHDScreen = useMediaQuery("(max-width: 1800px)");

const limit = ref(false);

const props = defineProps<Props>();
const items = ref<
  {
    image_src: string;
  }[]
>([]);

const shownItems = computed(() => {
  if (isMobile.value) {
    return items.value.slice(0, 2);
  }
  if (isHDScreen.value) {
    return items.value.slice(0, 3);
  }

  return items.value;
});

onMounted(async () => {
  const itemsReq = await supabase.rpc("get_top_expensive_items", {
    p_box_id: props.box.id,
  });
  const boxLimit = await useLimitsByBoxID(props.box.id);
  limit.value = !!boxLimit;
  items.value = itemsReq.data;
});
</script>

<style scoped lang="scss">
.slot-wrapper {
  .image-wrapper {
    @apply transition-all duration-300;
  }
  &:hover {
    .image-wrapper {
      @apply gap-4;
    }
    .button-wrapper {
      @apply w-20;
    }
  }
  @media (hover: none) {
    .button-wrapper {
      @apply w-20;
    }
  }
}
</style>
