<template>
  <div v-if="!isLoggedIn" class="flex items-center justify-center gap-2">
    <RouterLink to="/signup" class="px-4 py-2 bg-primary-300 rounded-lg"
      >Sign Up</RouterLink
    >
    <RouterLink
      to="/login"
      class="px-4 py-2 text-primary-300 border border-primary-300 rounded-lg"
      >Log In</RouterLink
    >
  </div>
  <UserInfo v-else />
</template>

<script setup lang="ts">
import { PhGearSix, PhPerson, PhSignOut, PhCoins } from "@phosphor-icons/vue";
import { useUserStore, AuthState } from "../../stores/UserStore";
import { RouterLink } from "vue-router";
import { useLiveCoins } from "../../composable/useLiveCoins";
import { supabase } from "../../services/SupabaseService";
import { computed, onMounted, ref } from "vue";
import { getAdminStatus } from "../../services/userService";
import UserInfo from "./UserInfo.vue";

const userStore = useUserStore();
const isAdmin = ref(false);

const isLoggedIn = computed(() => userStore.authState == AuthState.SignedIn);

async function handleLogout() {
  supabase.auth.signOut();
  userStore.signOut();
}
</script>

<style scoped lang="scss"></style>
