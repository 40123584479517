<template>
  <div
    class="h-60 w-48 bg-secondary-400 border border-secondary-600 rounded-lg flex justify-center items-center"
  >
    <span class="text-xl">+ </span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
