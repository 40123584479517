<template>
  <div
    class="modal w-full m-4 md:w-1/4 h-2/4 border border-secondary-300 rounded-lg bg-secondary-300 flex justify-between items-center flex-col p-4"
  >
    <div class="flex flex-col items-center">
      <h1 class="text-2xl">Congratulations</h1>
      <h2 v-if="!modalStore.additionalData.isDemo">you won:</h2>
      <h2 v-else>you would have won:</h2>
    </div>

    <div class="h-2/3 flex items-center flex-col gap-2 justify-center">
      <WinnableLot
        :winnable="modalStore.additionalData.winnable"
        :rarity="modalStore.additionalData.rarity"
      ></WinnableLot>
      {{ modalStore.additionalData.winnable.name }}
    </div>
    <div
      v-if="
        modalStore.additionalData.isDemo && !modalStore.additionalData.loggedIn
      "
    >
      Sign up now to win more!
    </div>
    <button
      @click="handleSignup"
      class="px-4 py-2 bg-primary-300 rounded-lg w-full"
      v-if="
        modalStore.additionalData.isDemo && !modalStore.additionalData.loggedIn
      "
    >
      Sign Up
    </button>
    <button
      class="px-4 py-2 bg-primary-300 rounded-lg w-full"
      @click="handleClose"
    >
      Close
    </button>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useModalStore } from "../../../stores/ModalStore";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";

const modalStore = useModalStore();
const router = useRouter();

function handleClose() {
  modalStore.visible = false;
}
function handleSignup() {
  router.push("/signup");
  modalStore.visible = false;
}
</script>

<style scoped lang="scss">
.modalContent {
  @apply w-full;
}
</style>
