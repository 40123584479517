import { useIsAdmin } from "../composable/useIsAdmin";
import { useUserStore } from "../stores/UserStore";

export const adminGuard = async (to: any, from: any, next: any) => {
  if (import.meta.env.DEV) next();
  const userStore = useUserStore();
  const user = userStore.user;
  if (!user) return next("/login");

  const isAdmin = await useIsAdmin(user!.id);
  console.log(isAdmin);
  if (isAdmin) {
    next();
  } else {
    next({ name: "Home" });
  }
};
