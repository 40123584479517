import { defineStore } from "pinia";
import { Shipment, Winnable } from "../model/supabase";
import WinnableWrapper from "../components/Modal/content/WinnableWrapper.vue";
import { Component } from "vue";
import WinModal from "../components/Modal/Box/WinModal.vue";
import BuyModal from "../components/Modal/Box/BuyModal.vue";
import ShipNowConfirmModal from "../components/Modal/inventory/shipNowConfirmModal.vue";
import AddressModal from "../components/Modal/Box/AddressModal.vue";
import AddressModifierModal from "../components/Modal/inventory/addressModifierModal.vue";
import ShipmentModal from "../components/Modal/inventory/shipmentModal.vue";
import EditPercentagesModal from "../components/Modal/content/EditPercentagesModal.vue";
import BlindOpenModal from "../components/Modal/Box/BlindOpenModal.vue";
import HandleNotifications from "../components/Modal/inventory/handleNotifications.vue";
import SimulateBoxOpenModal from "../components/Modal/content/SimulateOpenModal.vue";
import { BoxWrapper } from "../classes/BoxWrapper";

type ModalStoreState = {
  visible: boolean;
  returnWinnable?: Winnable[];
  additionalData?: any;
  component: Component;
};

export const useModalStore = defineStore("ModalStore", {
  state: (): ModalStoreState => {
    return {
      visible: false,
      component: WinnableWrapper,
      additionalData: {},
    };
  },
  getters: {},
});

export function openModal() {
  const store = useModalStore();

  store.visible = true;

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (store.returnWinnable || !store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openWinModal(
  winnable: Winnable,
  isDemo: boolean,
  loggedIn: boolean,
  rarity: number
) {
  const store = useModalStore();

  store.visible = true;
  store.component = WinModal;
  store.additionalData = { winnable, isDemo, loggedIn, rarity };

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openBuyModal() {
  const store = useModalStore();

  store.visible = true;
  store.component = BuyModal;

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openConfirmModal() {
  const store = useModalStore();

  store.visible = true;
  store.component = ShipNowConfirmModal;

  return new Promise<boolean>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        const data = !!store.additionalData.consent;

        listner();
        store.$reset();
        resolve(data);
      }
    });
  });
}

export function openAddressInfoModal() {
  const store = useModalStore();

  store.visible = true;
  store.component = AddressModal;

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openAddressModifier() {
  const store = useModalStore();

  store.visible = true;
  store.component = AddressModifierModal;

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openShipmentInfoModal(shipment: Shipment) {
  const store = useModalStore();

  store.visible = true;
  store.component = ShipmentModal;
  store.additionalData = { shipmentID: shipment.id };

  return new Promise<Winnable[]>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve(store.returnWinnable!);
      }
    });
  });
}

export function openPercentageModal(
  winnables: Winnable[],
  price: number,
  loseChance: number
) {
  const store = useModalStore();

  store.visible = true;
  store.component = EditPercentagesModal;
  store.additionalData = { winnables, price, loseChance };

  return new Promise<Record<string, number>>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        const chances = store.additionalData.chances;
        listner();
        store.$reset();
        resolve(chances);
      }
    });
  });
}

export function openBlindOpenModal(
  boxWrapper: BoxWrapper,
  hasShipping: boolean
) {
  const store = useModalStore();

  store.visible = true;
  store.component = BlindOpenModal;
  store.additionalData = { boxWrapper, hasShipping };

  return new Promise<{ shipped: boolean; bought: boolean }>(
    (resolve, reject) => {
      const listner = store.$subscribe((state) => {
        if (!store.visible) {
          const shipped = !!store.additionalData.shipped;
          const bought = !!store.additionalData.bought;
          listner();
          store.$reset();
          resolve({
            shipped,
            bought,
          });
        }
      });
    }
  );
}

export function openHandleNotifications() {
  const store = useModalStore();

  store.visible = true;
  store.component = HandleNotifications;

  return new Promise<void>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve();
      }
    });
  });
}

export function openSimulateBoxOpenModal(
  winnables: Winnable[],
  weightMap: Record<string, number>,
  boxPrice: number
) {
  const store = useModalStore();

  store.visible = true;
  store.component = SimulateBoxOpenModal;
  store.additionalData = { winnables, weightMap, boxPrice };

  return new Promise<void>((resolve, reject) => {
    const listner = store.$subscribe((state) => {
      if (!store.visible) {
        listner();
        store.$reset();
        resolve();
      }
    });
  });
}
