<template>
  <AddressCard
    @submit="handleClose()"
    isModal
    @close="handleClose"
  ></AddressCard>
</template>

<script setup lang="ts">
import { useModalStore } from "../../../stores/ModalStore";
import WinnableLot from "../../admin/NewBox/WinnableLot.vue";
import AddressCard from "../../signup/AddressCard.vue";

const modalStore = useModalStore();

function handleClose() {
  modalStore.visible = false;
}
</script>

<style scoped lang="scss">
.modalContent {
  @apply w-full;
}
</style>
