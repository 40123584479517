import { User } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { Coins } from "../model/supabase";

export enum AuthState {
  SignedIn,
  Anon,
}

export type UserStoreState = {
  user?: User;
  coins: number;
  authState: AuthState;
};

export const useUserStore = defineStore("user", {
  state: (): UserStoreState => {
    return {
      user: undefined,
      coins: 0,
      authState: AuthState.Anon,
    };
  },
  actions: {
    signIn(user: User, coins: number) {
      this.user = user;
      this.coins = coins;
      this.authState = AuthState.SignedIn;
    },
    signOut() {
      this.user = undefined;
      this.coins = 0;
      this.authState = AuthState.Anon;
    },
  },
});
